import { Link, useNavigate } from "react-router-dom";
import { BsPlus } from "react-icons/bs";
import NewWorkersTable from "./workersTable/NewWorkersTable";
import MainWorkersTable from "./workersTable/MainWorkersTable";
import ContractWorkersTable from "./workersTable/ContractWorkersTable";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  exportCreateEmployees,
  getCompanyEmployee,
} from "../../services/employee.service";

import Modal from "@mui/material/Modal";
import { ToastContainer, toast } from "react-toastify";
import OrgChartComponent from "./departmentOrgChart/OrgChartComponent";
import { CircularProgress } from "@mui/material";
import { IoCloudUploadOutline } from "react-icons/io5";
import { SiMicrosoftexcel } from "react-icons/si";

const Workers = () => {
  const { t, i18n } = useTranslation();
  const [sending, setSending] = useState(false);
  const [activeTable, setActiveTable] = useState("main");
  const [employees, setEmployees] = useState([]);
  const [file, setFile] = useState(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    getCompanyEmployee().then((data) => {
      setEmployees(data.data);
    });
  }, []);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);
  const handleDownloadExcel = async () => {
    try {
      const response = await fetch("/excel/EmployeesExcelFileTemplate.xlsx");
      const blob = await response.blob();

      // Create a temporary anchor element
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.download = "EmployeesExcelFileTemplate.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading Excel file:", error);
    }
  };

  const handleFileInputChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
      if (fileExtension === "xlsx" || fileExtension === "xls") {
        setFile(selectedFile);
      } else {
        setFile(null);
        toast.error(t("toast.selectExcelFile"));
      }
    }
  };

  const handleTableChange = (table) => {
    setActiveTable(table);
    navigate(`/company/employee/${table}`);
  };
  
  const renderActiveTable = () => {
    switch (activeTable) {
      case "main":
        return <MainWorkersTable />;
      case "new":
        return <NewWorkersTable />;
      case "contract":
        return <ContractWorkersTable />;
      case "chart":
        return <OrgChartComponent />;
      default:
        return null;
    }
  };
  const numberOfEmployees = employees.length === 0 ? 0 : employees.length - 1;

  return (
    <div>
      <div className="m-auto w-10/12 pt-11">
        <div className="flex justify-between">
          <div>
            <p className="text-2xl ">{t("EmployeeTable.title")}</p>
            <p className="text-base text-[#777985]">
              {i18n.language === "rn"
                ? `${t("EmployeeTable.registered")} ${numberOfEmployees} ${t(
                    "EmployeeTable.people"
                  )}`
                : `${numberOfEmployees} ${t("EmployeeTable.howMuchEmployee")}`}
            </p>
          </div>
          <div className="flex items-center">
            <button
              onClick={handleOpen}
              className="flex items-center text-white bg-[#55D6A0] hover:opacity-50 py-2 px-3 rounded-lg me-2 gap-2"
            >
              <SiMicrosoftexcel />
              {t("EmployeeTable.addExcel")}
            </button>
            <Link to="/company/employee/add">
              <button className="flex items-center text-white bg-[#324D72] hover:bg-[#324D90] py-2 px-3 rounded-lg">
                {t("EmployeeTable.employeeAddButton")}
                <BsPlus className="text-white w-6 h-6 font-bold ms-2" />
              </button>
            </Link>
          </div>
        </div>
        <Modal open={open} onClose={handleClose}>
          <div className="absolute top-1/2 left-1/2 w-[650px]  rounded-xl transform -translate-x-1/2 -translate-y-1/2 bg-white p-8 shadow-xl">
            <p className="text-xl text-bold">
              {" "}
              {t("EmployeeTable.usingExcelToAddMany")}
            </p>
            <div className="flex justify-between items-center mt-4">
              <p className="text-lg text-[#324d72]">
                {t("EmployeeTable.etcExcel")}
              </p>
              <button
                onClick={handleDownloadExcel}
                className="flex items-center text-white bg-[#55D6A0] opacity-50 py-2 px-3 rounded-lg me-2 hover:opacity-100 gap-2"
              >
                <img src="/icons/excel.png" />
                {t("EmployeeTable.download")}
              </button>
            </div>
            <p className="text-xl font-bold mt-7">
              {t("EmployeeTable.ChooseWorkerExcel")}
            </p>
            <div className="flex justify-between items-center mt-4">
              {file === null ? (
                <p className="p-2 border-black border-2 rounded-lg w-full me-5 italic">
                  {t("EmployeeTable.ChooseExcel")}
                  <input
                    id="fileInput"
                    type="file"
                    className="hidden"
                    onChange={handleFileInputChange}
                  />
                </p>
              ) : (
                <p className="p-2 border-black border-2 rounded-lg w-full me-5 italic">
                  {file.name} {/* shows selected file name */}
                </p>
              )}
              <button className="border-2 p-1 rounded-full border-[#324d72] text-[#324d72] hover:bg-[#324d72]  hover:text-white">
                <label htmlFor="fileInput" className="cursor-pointer">
                  <BsPlus className="h-7 w-7" />
                </label>
                <input
                  id="fileInput"
                  type="file"
                  className="hidden"
                  accept=".xlsx, .xls"
                  onChange={handleFileInputChange}
                />
              </button>
            </div>
            <div className="w-full flex justify-end mt-6">
              <button
                // disabled={sending}
                onClick={async () => {
                  if (file === null) {
                    toast.error(t("toast.addExcelFile"));
                    return;
                  }
                  if (sending === false) {
                    const formData = new FormData();
                    formData.append("file", file);
                    setSending(true);
                    try {
                      await exportCreateEmployees(formData).then((val) => {});
                      // handleClose();
                      setFile(null);
                      toast.success(t("toast.successfulSent"));
                      setSending(false);
                    } catch (error) {
                      toast.error(t("toast.excelFailed"));
                      setSending(false);
                    }
                  }
                }}
                className="text-lg px-6 py-2 rounded-lg text-white bg-[#324d72] hover:opacity-50"
              >
                {sending ? (
                  <CircularProgress size={25} />
                ) : (
                  <div className="flex items-center gap-2">
                    <IoCloudUploadOutline />
                    {t("EmployeeTable.add")}
                  </div>
                )}
              </button>
            </div>
          </div>
        </Modal>

        <div className="py-10 flex w-full">
          <div
            className={`w-1/3 text-center ${
              activeTable === "main" ? "border-b-2 border-[#FD6B3A]" : ""
            }`}
          >
            <button
              className={`text-xl text-center font-bold  ${
                activeTable === "main" ? "text-[#FD6B3A]" : "text-[#777985]"
              }`}
              onClick={() => handleTableChange("main")}
            >
              {t("EmployeeTable.officialTableTitle")}
            </button>
          </div>
          <div
            className={`w-1/3 text-center ${
              activeTable === "new" ? "border-b-2 border-[#FD6B3A]" : ""
            }`}
          >
            <button
              className={`text-xl font-bold text-center ${
                activeTable === "new" ? "text-[#FD6B3A]" : "text-[#777985]"
              }`}
              onClick={() => handleTableChange("new")}
            >
              {t("EmployeeTable.newTableTitle")}
            </button>
          </div>
          <div
            className={`w-1/3 text-center ${
              activeTable === "contract" ? "border-b-2 border-[#FD6B3A]" : ""
            }`}
          >
            <button
              className={`text-xl font-bold text-center ${
                activeTable === "contract" ? "text-[#FD6B3A]" : "text-[#777985]"
              }`}
              onClick={() => handleTableChange("contract")}
            >
              {t("EmployeeTable.contractTableTitle")}
            </button>
          </div>
          <div
            className={`w-1/3 text-center ${
              activeTable === "chart" ? "border-b-2 border-[#FD6B3A]" : ""
            }`}
          >
            <button
              className={`text-xl font-bold text-center ${
                activeTable === "chart" ? "text-[#FD6B3A]" : "text-[#777985]"
              }`}
              onClick={() => handleTableChange("chart")}
            >
              {t("OrgChart.title")}
            </button>
          </div>
        </div>
        {renderActiveTable()}
        <ToastContainer />
      </div>
    </div>
  );
};

export default Workers;
