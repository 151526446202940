import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { MdOutlineGroup } from "react-icons/md";
import { FaChevronRight } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { getOnaList } from "../../services/ona.servise";
import Loading from "../../components/uncommon/Loading";
import { MdOutlineModeEditOutline } from "react-icons/md";

const Ona = () => {
  const navigate = useNavigate();
  const [height, setHeight] = useState(window.innerHeight - 280);
  const [height2, setHeight2] = useState(window.innerHeight / 2 - 160);
  const [ona, setOna] = useState(null);
  const [endedOna, setEndedOna] = useState(null);
  const [savedOna, setSavedOna] = useState(null);
  const [loading, setLoading] = useState(true);
  const [height3, setHeight3] = useState(window.innerHeight - 100);
  const [hover, setHover] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    getOnaList()
      .then((data) => setOna(data.data))
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight - 200);
      setHeight2(window.innerHeight / 2 - 100);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="w-10/12 m-auto pt-11">
      <div className="flex items-center justify-between">
        <p className="text-[24px] text-[#222]">{t("ona.title")}</p>
        {ona !== null || endedOna !== null || savedOna !== null ? (
          <button
            onClick={() => {
              navigate("create");
            }}
            className="bg-[#324d72] text-sm rounded-xl px-8 py-2 font-semibold text-white flex items-center gap-2"
          >
            {" "}
            {t("ona.createBtn")} <FaPlus />
          </button>
        ) : (
          ""
        )}
      </div>
      {ona === null && endedOna === null && savedOna === null ? (
        <div className="h-screen flex items-center justify-center">
          <div className="flex flex-col items-center gap-4 pb-60">
            <img src="/ona/ona2.png" alt="ona" />
            <p className="text-center text-[24px] text-[#222] font-semibold">
              {t("ona.noOna")}
            </p>
            <button
              onClick={() => {
                navigate("create");
              }}
              className="bg-[#324d72] rounded-xl px-4 py-2 font-semibold text-white flex items-center gap-2"
            >
              {t("ona.createBtn")} <FaPlus />
            </button>
          </div>
        </div>
      ) : (
        <div className="flex w-full gap-10 items-start justify-between mt-10">
          <div className="w-1/2">
            <p className="font-semibold text-lg text-[#222] mb-6">
              {t("ona.ongoingOna")}
            </p>
            <div style={{ height: `${height}px`, overflowY: "auto" }}>
              {ona === null ? (
                <div className="h-full flex items-center justify-center">
                  <div className="flex flex-col items-center">
                    <img src="/ona/empty.png" alt="empty" />
                    <p className="text-[#1E293B] mt-2">{t("ona.noOna2")}</p>
                  </div>
                </div>
              ) : (
                <div>
                  {ona
                    .slice()
                    .reverse()
                    .map((items) => {
                      return (
                        <div
                          key={items.id}
                          className="w-full rounded-lg shadow-lg px-4 py-6 h-[141px] flex flex-col justify-between mb-4 bg-white"
                        >
                          <div className="flex justify-between">
                            <p className="text-[#1E293B] font-semibold">
                              {items.name}
                            </p>
                            <button
                              onClick={() => {
                                navigate(`edit/${items.id}`);
                              }}
                              className={`text-sm text-[#222] opacity-70 hover:opacity-100 `}
                            >
                              <MdOutlineModeEditOutline className="border border-[#222] p-0.5 rounded-full text-xl" />
                            </button>
                          </div>
                          <div className="flex items-end justify-between">
                            <div>
                              <p className="text-[#636466] text-xs">
                                {t("ona.invited")}
                              </p>
                              <p className="text-xs text-[#1E293B] flex items-center">
                                <MdOutlineGroup className="h-4 w-4" />
                                <span className="font-semibold ps-0.5 pe-1">
                                  {items.sentEmployee}
                                </span>
                                {/* Хэлтэс нэр */}
                              </p>
                            </div>
                            <div>
                              <button
                                onClick={() => {
                                  navigate(`report/${items.id}`);
                                }}
                                className="flex items-center gap-2 text-sm text-[#222] opacity-70 hover:opacity-100"
                              >
                                {t("ona.detail")} <FaChevronRight />
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
          </div>
          <div className="w-1/2">
            <div className="mb-4">
              <p className="font-semibold text-lg text-[#222] mb-6">
                {t("ona.savedOna")}
              </p>
              <div
                className={`shadow-xl ${
                  savedOna !== null ? "rounded-xl" : "rounded-none"
                }`}
                style={{ height: `${height2}px`, overflowY: "auto" }}
              >
                {savedOna === null ? (
                  <div className=" flex items-center justify-center">
                    <div className="flex flex-col items-center">
                      <img src="/ona/empty.png" alt="empty" />
                      <p className="text-[#1E293B] mt-2">{t("ona.noOna2")}</p>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="w-full rounded-lg shadow-lg px-4 py-6 h-[141px] flex flex-col justify-between mb-4 bg-white">
                      <div className="flex justify-between">
                        <p className="text-[#1E293B] font-semibold">Ona name</p>
                        <p className="text-xs text-[#636466]">2024/06/12</p>
                      </div>
                      <div className="flex items-end justify-between">
                        <div>
                          <p className="text-[#636466] text-xs">
                            {t("ona.invited")}
                          </p>
                          <p className="text-xs text-[#1E293B] flex items-center">
                            <MdOutlineGroup className="h-4 w-4" />
                            <span className="font-semibold ps-0.5 pe-1">
                              25
                            </span>
                            Хэлтэс нэр
                          </p>
                        </div>
                        <button className="flex items-center gap-2 text-sm text-[#222] opacity-70 hover:opacity-100">
                          {t("ona.detail")} <FaChevronRight />
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="mb-4">
              <p className="font-semibold text-lg text-[#222] mb-6">
                {t("ona.endedOna")}
              </p>
              <div
                className={`shadow-xl ${
                  endedOna === null ? "rounded-xl" : "rounded-none"
                }`}
                style={{ height: `${height2}px`, overflowY: "auto" }}
              >
                {endedOna === null ? (
                  <div className=" flex items-center justify-center">
                    <div className="flex flex-col items-center">
                      <img src="/ona/empty.png" alt="empty" />
                      <p className="text-[#1E293B] mt-2">{t("ona.noOna2")}</p>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="w-full rounded-lg shadow-lg px-4 py-6 h-[141px] flex flex-col justify-between mb-4 bg-white">
                      <div className="flex justify-between">
                        <p className="text-[#1E293B] font-semibold">Ona name</p>
                        <p className="text-xs text-[#636466]">2024/06/12</p>
                      </div>
                      <div className="flex items-end justify-between">
                        <div>
                          <p className="text-[#636466] text-xs">
                            {t("ona.invited")}
                          </p>
                          <p className="text-xs text-[#1E293B] flex items-center">
                            <MdOutlineGroup className="h-4 w-4" />
                            <span className="font-semibold ps-0.5 pe-1">
                              25
                            </span>
                            Хэлтэс нэр
                          </p>
                        </div>
                        <button className="flex items-center gap-2 text-sm text-[#222] opacity-70 hover:opacity-100">
                          {t("ona.detail")} <FaChevronRight />
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Ona;
