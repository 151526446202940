import React, { useEffect, useRef, useState } from "react";
import Loading from "../../../components/uncommon/Loading";
import { useParams } from "react-router-dom";
import { changeAssPlayerAppliedToInvited } from "../../../services/assessment.service";

import {
  declinePlayer,
  getPlayer,
  inviteInterviewPlayer,
  waitListPlayer,
} from "../../../services/assessmentPlayer.service";
import { Bar, Pie } from "react-chartjs-2";
import { TfiSave } from "react-icons/tfi";
import { AiOutlinePrinter } from "react-icons/ai";
import { SlInfo } from "react-icons/sl";
import NormalDistributionChart from "../../../components/uncommon/normalDistributionChart";
import { getPlayerReport } from "../../../services/game.service";
import { I18nContext, useTranslation } from "react-i18next";
import { IoMdArrowDropdown } from "react-icons/io";
import { ToastContainer, toast } from "react-toastify";
import html2pdf from "html2pdf.js";
import { toJpeg } from "html-to-image";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import "../../../index.scss";

const HoverComponent = ({ hoverText, top, left, svg, textStyle }) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <div
      className="relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <SlInfo size={20} />

      {isHovering && (
        <div style={{ position: "absolute", top, left }} className=" z-30">
          <div className="relative">
            {svg}
            <p className={`absolute z-50  text-center ${textStyle}`}>
              {hoverText}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

const NewGameData = () => {
  const { id } = useParams();
  const [temperature1, setTemperature1] = useState(0);
  const [data, setData] = useState(null);
  const [report, setReport] = useState(null);
  const [dataDone, setDataDone] = useState(false);
  const [reportDone, setReportDone] = useState(false);
  const { t, i18n } = useTranslation();
  const [overallCompatibility, setOverallCompatibility] = useState(0);
  const [mostNeed, setMostNeed] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [doesntplay, setDoesntPlay] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const options = [
    t("assessmentProfile.gameInvite"),
    t("assessmentProfile.inviteInterview"),
    // t("assessmentProfile.waitList"),
  ];
  const skills = [
    "curiosity",
    "riskTaking",
    "adaptability",
    "cooperative",
    "trusting",
    "assertiveness",
    "consistence",
    "planning",
  ];

  useEffect(() => {
    getPlayer(id)
      .then((data) => {
        setData(data.data[0]);

        setDataDone(true);
      })
      .catch((err) => {
        setDoesntPlay(true);
      });
    getPlayerReport(id)
      .then((data) => {
        setReport(data.data);
        setReportDone(true);
      })
      .catch((err) => {
        setDoesntPlay(true);
        console.log(err);
      });
  }, [id]);

  useEffect(() => {
    if (report) {
      const filteredSkillsArray = report.skillDetails.filter((s) => {
        return s.expectedLevel === 5;
      });
      setMostNeed(filteredSkillsArray);
    }
  }, [report]);

  const scrollToTop = () => {
    document.documentElement.scrollTop = 20;
  };

  const longImageRef = useRef(null);

  const downloadLongImage = async () => {
    const downloadButton = document.querySelectorAll("#downloadButton");
    const sections = document.querySelectorAll(".section");
    const bottomSpaces = document.querySelectorAll(".bottom-Space");
    const bottomPercent = document.querySelectorAll(".bottom-percent");

    downloadButton.forEach((button) => {
      button.style.display = "none";
    });

    bottomSpaces.forEach((element) => {
      element.style.marginBottom = "20px";
    });

    bottomPercent.forEach((element) => {
      element.style.marginTop = "-20px";
    });

    const pdf = new jsPDF({
      format: "a4",
    });

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];

      const canvas = await html2canvas(section, { backgroundColor: "#ffffff" });
      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;

      const scaleFactor = Math.min(
        pdf.internal.pageSize.getWidth() / imgWidth,
        pdf.internal.pageSize.getHeight() / imgHeight
      );

      if (i > 0) {
        pdf.addPage("a4");
      }

      pdf.addImage(
        imgData,
        "JPEG",
        5,
        5,
        imgWidth * scaleFactor,
        imgHeight * scaleFactor
      );
    }

    pdf.save(`${data.name}.pdf`);
    downloadButton.forEach((button) => {
      button.style.display = "flex";
      button.style.justifyContent = "flex-end";
      button.style.marginTop = "10px !important";
    });

    bottomSpaces.forEach((element) => {
      element.style.marginBottom = "0px";
    });

    bottomPercent.forEach((element) => {
      element.style.marginTop = "0px";
    });
  };

  const handleDownload = () => {
    let sw = window.document.getElementsByClassName("downloadBtn")[0];
    let sw1 = window.document.getElementsByClassName("downloadBtn")[1];
    const tmp = sw.style.display;
    const tmp1 = sw1.style.display;

    sw.style.display = "none";
    sw1.style.display = "none";

    const element = window.document.getElementsByClassName("reportDownload")[0];
    if (element) {
      html2pdf()
        .from(element)
        .save(`${data.name}.pdf`)
        .then(() => {
          console.log("PDF saved successfully");
          // Restore display after PDF is generated and saved
          sw.style.display = tmp;
          sw1.style.display = tmp1;
        })
        .catch((error) => console.error("Error saving PDF:", error));
    } else {
      console.error('Element with class "reportDownload" not found.');
      // Restore display if reportDownload element not found
      sw.style.display = tmp;
      sw1.style.display = tmp1;
    }
  };

  // const downloadLongImage = async () => {
  //   const container = longImageRef.current;

  //   // Get the dimensions of the HTML content
  //   const width = container.offsetWidth;
  //   const height = container.offsetHeight;

  //   // Set background color to white before converting to image
  //   container.style.backgroundColor = "white";

  //   // Use html2canvas to capture the container as an image
  //   const canvas = await html2canvas(container, { backgroundColor: null });
  //   const imgData = canvas.toDataURL('image/jpeg');

  //   // Calculate the page size and scale factor
  //   const pdf = new jsPDF({
  //     orientation: 'portrait',
  //     unit: 'px',
  //     format: 'a4'
  //   });

  //   const pageWidth = pdf.internal.pageSize.getWidth();
  //   const pageHeight = pdf.internal.pageSize.getHeight();
  //   const imgWidth = pageWidth;
  //   const imgHeight = (height * pageWidth) / width;

  //   // Calculate the number of pages needed
  //   let position = 0;
  //   const pages = Math.ceil(imgHeight / pageHeight);

  //   // Add each page to the PDF
  //   for (let i = 0; i < pages; i++) {
  //     if (i > 0) {
  //       pdf.addPage();
  //     }
  //     pdf.addImage(
  //       imgData,
  //       'JPEG',
  //       0,
  //       position,
  //       imgWidth,
  //       imgHeight
  //     );
  //     position -= pageHeight;
  //   }

  //   // Save the PDF document
  //   pdf.save(`${data.name}.pdf`);

  //   // Reset background color after downloading image
  //   container.style.backgroundColor = '';
  // };

  if (doesntplay) {
    return (
      <div className="w-10/12 m-auto pt-11 ">
        <div className="flex justify-between">
          <div className="flex items-center gap-5">
            <img
              className="w-[100px] h-[100px] border-2 rounded-full border-black "
              src="/user.svg"
              alt="user"
            />
            <div>
              <p className="text-2xl font-bold">
                {data?.lastName} {data?.name}
              </p>
              <p className="text-xl my-4">{data?.assessmentName}</p>
              <div>
                <div className="flex items-center gap-5">
                  <p>Утас:</p>
                  <p>{data?.phoneNumber}</p>
                </div>
                <div className="flex items-center gap-5">
                  <p>Э-шуудан:</p> <p>{data?.email}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className="mt-10 text-2xl font-bold"> Тоглогч тоглоогүй байна</p>
      </div>
    );
  }

  if (!dataDone || !reportDone) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  const handleSelect = (option) => {
    console.log(option);
    setSelectedOption(option);
    setIsOpen(false);

    if (option === t("assessmentProfile.gameInvite")) {
      if (data.ci === null || data.mpi === null) {
        toast.success(t("assessmentProfile.requestSuccess"));
        changeAssPlayerAppliedToInvited({ assPlayerId: id });
        setData({ ...data, completeType: "invited" });
      } else {
        toast.error(t("toast.Error"));
      }
    } else if (option === t("assessmentProfile.inviteInterview")) {
      inviteInterviewPlayer(id)
        .then((res) => {
          toast.success(t("assessmentProfile.invitedInterviewSuccess"));
        })
        .catch((err) => {
          toast.error(t("toast.Error"));
        });
    } else if (option === t("assessmentProfile.waitList")) {
      waitListPlayer(id)
        .then(() => {
          toast.success("");
        })
        .catch(() => {
          toast.error("");
        });
    }
  };

  const handleDecline = () => {
    declinePlayer(id)
      .then((res) => {
        toast.success(t("assessmentProfile.requestSuccess"));
      })
      .catch((err) => {
        toast.error(t("toast.Error"));
      });
  };

  const findSkill = (name) =>
    report.skillDetails.find((skill) => skill.name === name);

  const getSkillName = (skill) => {
    if (i18n.language === "mn") {
      return skill.mnName;
    } else if (i18n.language === "rn") {
      return skill.ruName;
    }
    return skill.mnName;
  };

  let totalExpectedLevel = 0;
  skills.forEach((skill) => {
    totalExpectedLevel += findSkill(skill).expectedLevel;
  });

  const totalSkills = skills.length;
  const avgExpectedLevel = totalExpectedLevel / totalSkills;
  const expectedLevelPercent = (avgExpectedLevel / 5) * 100;

  // Sort skills based on expected level
  const sortedSkills = skills.sort(
    (a, b) => findSkill(b).expectedLevel - findSkill(a).expectedLevel
  );

  const top3Skills = sortedSkills
    .slice(0, 3)
    .map((skill) => getSkillName(findSkill(skill)));
  const bottom3Skills = sortedSkills
    .slice(-3)
    .map((skill) => getSkillName(findSkill(skill)));

  const getGradientColor = (value) => {
    const percentage = value * 25;
    return `linear-gradient(to left, #D9D9D9 ${
      100 - percentage
    }%, #66BCCE ${0}%)`;
  };

  const Cdata = {
    labels: mostNeed.map((e) => {
      return e.mnName;
    }),
    datasets: [
      {
        label: "Оролцогчийн үр дүн ",
        backgroundColor: "#66BCCE",
        borderColor: "#66BCCE",
        barThickness: 8,
        data: mostNeed.map((e) => {
          return e.level;
        }),
      },
      {
        label: "Шаардлагатай түвшин",
        backgroundColor: "#CECFD3",
        borderColor: "#CECFD3",
        barThickness: 8,
        data: mostNeed.map((e) => {
          return e.expectedLevel;
        }),
      },
    ],
  };

  const Cdata2 = {
    labels: report.top3Skills.map((item) => {
      if (i18n.language === "mn") {
        return item.mnName;
      } else if (i18n.language === "rn") {
        return item.ruName;
      } else {
        return item.mnName;
      }
    }),
    datasets: [
      {
        label: "Оролцогчийн чадвар",
        backgroundColor: "#324D72",
        data: report.top3Skills.map((item) => item.level),
      },
    ],
  };

  const Cdata3 = {
    labels: report.bottom3Skills.map((item) => {
      if (i18n.language === "mn") {
        return item.mnName;
      } else if (i18n.language === "rn") {
        return item.ruName;
      } else {
        return item.mnName;
      }
    }),
    datasets: [
      {
        label: "Оролцогчийн чадвар",
        backgroundColor: "#F18B8B",
        data: report.bottom3Skills.map((item) => item.level),
      },
    ],
  };

  const mpiremain = 100 - report.iqPercentage;
  const ciremain = 100 - report.personalityPercentage;

  const pieData = {
    labels: ["нийцэл", ""],
    datasets: [
      {
        data: [report.iqPercentage.toFixed(0), mpiremain],
        backgroundColor: ["transparent", "transparent"],
        borderColor: ["#06B496", "transparent"], // Clear inside border
        borderWidth: 2, // Add outer border
        borderAlign: "center", // Align outer border to center
        cutout: "99%", // Add a cutout to make space for the outer border
      },
    ],
  };

  const pieData2 = {
    labels: ["нийцэл", ""],
    datasets: [
      {
        data: [report.personalityPercentage.toFixed(0), ciremain],
        backgroundColor: ["transparent", "transparent"],
        borderColor: ["#06B496", "transparent"], // Clear inside border
        borderWidth: 2, // Add outer border
        borderAlign: "center", // Align outer border to center
        cutout: "99%", // Add a cutout to make space for the outer border
      },
    ],
  };

  const pieOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  const option = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    plugins: {
      legend: {
        position: "bottom",
      },
    },
    scales: {
      x: {
        reverse: true,
      },
      y: {
        position: "right",
      },
    },
  };

  const coptions2 = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        max: 5,
      },
    },
  };

  const formattedCi = parseFloat(data.ci).toFixed(2);
  const formattedmpi = parseFloat(data.mpi).toFixed(2);

  return (
    <div
      className="p-9/12 m-auto pt-11 reportDownloa relative"
      ref={longImageRef}
    >
      {console.log("report", report)}
      <div className="section">
        <div className="flex justify-between">
          <div className="flex items-center gap-5">
            <img
              className="w-[100px] h-[100px] border-2 rounded-full border-black "
              src="/user.svg"
              alt="user"
            />
            <div>
              <p className="text-2xl font-bold">
                {data.lastName} {data.name}
              </p>
              <p className="text-xl my-4">{data.assessmentName}</p>
              <div>
                <div className="flex items-center gap-5">
                  <p>Утас:</p>
                  <p>{data.phoneNumber}</p>
                </div>
                <div className="flex items-center gap-5">
                  <p>Э-шуудан:</p> <p>{data.email}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-10">
          <p className="text-lg font-bold mx-20">
            Ажлын байран дээр “Маш чухал” -аар сонгогдсон ур чадварууд
          </p>
        </div>
        <div className="flex justify-center mt-8 px-10">
          <div style={{ width: "1000px " }}>
            <Bar data={Cdata} options={option} />
          </div>
        </div>
        <div
          id="downloadButton"
          className="flex justify-end items-center gap-5 mt-10 downloadBtn"
        >
          <button
            onClick={() => {
              downloadLongImage();
            }}
            className="px-6 py-2 border rounded-lg text-lg font-bold flex items-center gap-2 border-[#324D72] hover:bg-black hover:text-white"
          >
            <TfiSave className="h-5 w-5" />
            Тайлан татаж авах
          </button>
          {/* <button className="px-6 py-2 border rounded-lg text-lg font-bold flex items-center gap-2 border-[#6CB7A1] hover:bg-black hover:text-white">
          <AiOutlinePrinter className="h-5 w-5" />
          Тайлан хэвлэх
        </button> */}
        </div>
      </div>
      <div className="section">
        <div className="text-center text-2xl mb-10 font-bold mt-20">
          <p>Дэлгэрэнгүй тайлан</p>
        </div>
        <div>
          <div className="w-full bg-[#D9D9D9] px-5 py-2 flex items-center bg-opacity-30 gap-2 mt-5">
            <HoverComponent
              svg={
                <svg
                  width="411"
                  height="225"
                  viewBox="0 0 411 225"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.24029 16.9481V16.919L4.23364 16.8908L0.343225 0.366924L24.6664 7.83639L24.7023 7.8474H24.7398H410.75V224.75H4.24029V16.9481Z"
                    fill="white"
                    stroke="#464A54"
                    strokeWidth="0.5"
                  />
                </svg>
              }
              textStyle={"ms-3 text-base top-10"}
              top={40}
              left={40}
              hoverText="Оюун ухааны чадвар нь ерөнхийлөн болон нарийвчлан дүгнэх чадвар, анхаарал, ой тогтоолт зэрэг олон чадваруудын нэгдлээр тодорхойлогддог. Матаког Метрикс тоглоомын оюун ухааны үзүүлэлтүүд нь Стэнфорд Бинегийн оюун ухааны онол, оношилгооны аргын бүтцэд үндэслэгдэн боловсруулагдсан болно."
            />
            <p className="text-xl font-bold bottom-Space">
              1-р түвшний оюун ухааны үзүүлэлтүүд
            </p>
          </div>
          <div>
            <div>
              <div className="flex justify-center">
                <table className="ms-10 mt-5">
                  <tbody>
                    <tr>
                      <td className="py-2 flex items-center pe-20">
                        <div className="w-10">
                          <img
                            src="/assets/report/inductive.png"
                            className="h-[30]px w-[30px]"
                            alt="logo"
                          />
                        </div>
                        <p className="text-lg font-bold">
                          {getSkillName(findSkill("inductive"))}
                        </p>
                      </td>
                      <td>
                        <div className="flex items-center gap-5">
                          <div className="flex items-center">
                            <div className="relative w-[400px]">
                              <IoMdArrowDropdown
                                className={`text-[#F18B8B] h-8 w-8 absolute -top-4 `}
                                style={{
                                  left: `${
                                    findSkill("inductive").expectedLevel * 24 -
                                    26
                                  }%`,
                                }}
                              />

                              <input
                                type="range"
                                max="5"
                                min="1"
                                value={findSkill("inductive").level - 1}
                                disabled
                                className="appearance-none w-full  rounded-full outline-none focus:outline-none overflow-hidden z-30"
                                style={{
                                  backgroundImage: getGradientColor(
                                    findSkill("inductive").level - 1
                                  ),
                                  cursor: "pointer",
                                }}
                              />
                              <div className="absolute top-[22px] -mt-1 h-1 w-full">
                                <span
                                  className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 bg-[#66BCCE]`}
                                  style={{ left: "2%" }}
                                ></span>
                                {[1, 2, 3, 4].map((value) => (
                                  <span
                                    key={value}
                                    className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 ${
                                      findSkill("inductive").level - 1 >= value
                                        ? "bg-[#66BCCE]"
                                        : "bg-[#D9D9D9]"
                                    }`}
                                    style={{ left: `${value * 24 + 2}%` }}
                                  ></span>
                                ))}
                              </div>
                            </div>
                          </div>
                          <HoverComponent
                            svg={
                              <svg
                                width="210"
                                height="193"
                                viewBox="0 0 258 193"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                                  fill="white"
                                  stroke="#464A54"
                                  stroke-width="0.5"
                                />
                              </svg>
                            }
                            top={10}
                            left={20}
                            textStyle={"text-sm mx-3 top-6"}
                            hoverText="Индуктив сэтгэлгээ буюу ерөнхийлөн дүгнэх чадвар нь ажиглалт, хувийн туршлага дээр үндэслэн ерөнхий учир шалтгаан, дүгнэлтийг гаргах чадвар юм."
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 flex items-center pe-20">
                        <div className="w-10">
                          <img
                            src="/assets/report/processingSpeed.png"
                            className="h-[30]px w-[30px]"
                            alt="logo"
                          />
                        </div>
                        <p className="text-lg font-bold">
                          {getSkillName(findSkill("processingSpeed"))}
                        </p>
                      </td>
                      <td>
                        <div className="flex items-center gap-5">
                          <div className="flex items-center">
                            <div className="relative w-[400px]">
                              <IoMdArrowDropdown
                                className={`text-[#F18B8B] h-8 w-8 absolute -top-4`}
                                style={{
                                  left: `${
                                    findSkill("processingSpeed").expectedLevel *
                                      24 -
                                    26
                                  }%`,
                                }}
                              />

                              <input
                                type="range"
                                max="5"
                                min="1"
                                value={findSkill("processingSpeed").level - 1}
                                disabled
                                className="appearance-none w-full  rounded-full outline-none focus:outline-none overflow-hidden z-30"
                                style={{
                                  backgroundImage: getGradientColor(
                                    findSkill("processingSpeed").level - 1
                                  ),
                                  cursor: "pointer",
                                }}
                              />
                              <div className="absolute top-[22px] -mt-1 h-1 w-full">
                                <span
                                  className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 bg-[#66BCCE]`}
                                  style={{ left: "2%" }}
                                ></span>
                                {[1, 2, 3, 4].map((value) => (
                                  <span
                                    key={value}
                                    className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 ${
                                      findSkill("processingSpeed").level - 1 >=
                                      value
                                        ? "bg-[#66BCCE]"
                                        : "bg-[#D9D9D9]"
                                    }`}
                                    style={{ left: `${value * 24 + 2}%` }}
                                  ></span>
                                ))}
                              </div>
                            </div>
                          </div>
                          <HoverComponent
                            svg={
                              <svg
                                width="210"
                                height="193"
                                viewBox="0 0 258 193"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                                  fill="white"
                                  stroke="#464A54"
                                  stroke-width="0.5"
                                />
                              </svg>
                            }
                            top={10}
                            left={20}
                            textStyle={"text-sm mx-3 top-10"}
                            hoverText="Гадаад орчинд мэдээллийг хүлээн авч, ойлгох болон түүнд хариу үйлдэл үзүүлэх хурдыг боловсруулах хурд тодорхойлдог."
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 flex items-center pe-20">
                        <div className="w-10">
                          <img
                            src="/assets/report/attention.png"
                            className="h-[30]px w-[30px]"
                            alt="logo"
                          />
                        </div>
                        <p className="text-lg font-bold">
                          {getSkillName(findSkill("attention"))}
                        </p>
                      </td>
                      <td>
                        <div className="flex items-center gap-5">
                          <div className="flex items-center">
                            <div className="relative w-[400px]">
                              <IoMdArrowDropdown
                                className={`text-[#F18B8B] h-8 w-8 absolute -top-4 `}
                                style={{
                                  left: `${
                                    findSkill("attention").expectedLevel * 24 -
                                    26
                                  }%`,
                                }}
                              />

                              <input
                                type="range"
                                max="5"
                                min="1"
                                value={findSkill("attention").level - 1}
                                disabled
                                className="appearance-none w-full  rounded-full outline-none focus:outline-none overflow-hidden z-30"
                                style={{
                                  backgroundImage: getGradientColor(
                                    findSkill("attention").level - 1
                                  ),
                                  cursor: "pointer",
                                }}
                              />
                              <div className="absolute top-[22px] -mt-1 h-1 w-full">
                                <span
                                  className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 bg-[#66BCCE]`}
                                  style={{ left: "2%" }}
                                ></span>
                                {[1, 2, 3, 4].map((value) => (
                                  <span
                                    key={value}
                                    className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 ${
                                      findSkill("attention").level - 1 >= value
                                        ? "bg-[#66BCCE]"
                                        : "bg-[#D9D9D9]"
                                    }`}
                                    style={{ left: `${value * 24 + 2}%` }}
                                  ></span>
                                ))}
                              </div>
                            </div>
                          </div>
                          <HoverComponent
                            svg={
                              <svg
                                width="210"
                                height="193"
                                viewBox="0 0 258 193"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                                  fill="white"
                                  stroke="#464A54"
                                  stroke-width="0.5"
                                />
                              </svg>
                            }
                            top={10}
                            left={20}
                            textStyle={"text-sm mx-3 top-6"}
                            hoverText="Хүн өөрийн 5 мэдрэхүйг удирдан, өөрийн зорилтот мэдээллийг авахын тулд гадаад орчинтой харилцан үйлчлэлд орж буй танин мэдэхүйн үйл явц нь анхаарал юм."
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 flex items-center pe-20">
                        <div className="w-10">
                          <img
                            src="/assets/report/quantitative.png"
                            className="h-[30]px w-[30px]"
                            alt="logo"
                          />
                        </div>
                        <p className="text-lg font-bold">
                          {getSkillName(findSkill("quantitativeReasoing"))}
                        </p>
                      </td>
                      <td>
                        <div className="flex items-center gap-5">
                          <div className="flex items-center">
                            <div className="relative w-[400px]">
                              <IoMdArrowDropdown
                                className={`text-[#F18B8B] h-8 w-8 absolute -top-4 `}
                                style={{
                                  left: `${
                                    findSkill("quantitativeReasoing")
                                      .expectedLevel *
                                      24 -
                                    26
                                  }%`,
                                }}
                              />
                              <input
                                type="range"
                                max="5"
                                min="1"
                                value={
                                  findSkill("quantitativeReasoing").level - 1
                                }
                                disabled
                                className="appearance-none w-full  rounded-full outline-none focus:outline-none overflow-hidden z-30"
                                style={{
                                  backgroundImage: getGradientColor(
                                    findSkill("quantitativeReasoing").level - 1
                                  ),
                                  cursor: "pointer",
                                }}
                              />
                              <div className="absolute top-[22px] -mt-1 h-1 w-full">
                                <span
                                  className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 bg-[#66BCCE]`}
                                  style={{ left: "2%" }}
                                ></span>
                                {[1, 2, 3, 4].map((value) => (
                                  <span
                                    key={value}
                                    className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 ${
                                      findSkill("quantitativeReasoing").level -
                                        1 >=
                                      value
                                        ? "bg-[#66BCCE]"
                                        : "bg-[#D9D9D9]"
                                    }`}
                                    style={{ left: `${value * 24 + 2}%` }}
                                  ></span>
                                ))}
                              </div>
                            </div>
                          </div>
                          <HoverComponent
                            svg={
                              <svg
                                width="210"
                                height="193"
                                viewBox="0 0 258 193"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                                  fill="white"
                                  stroke="#464A54"
                                  stroke-width="0.5"
                                />
                              </svg>
                            }
                            top={10}
                            left={20}
                            textStyle={"text-sm mx-3 top-14"}
                            hoverText="Тоон өгөгдөл, хувьсагчдад тоон шинжилгээ, үйлдэл, дүгнэлт хийх чадварыг тоон сэтгэлгээ тодорхойлдог"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 flex items-center pe-20">
                        <div className="w-10">
                          <img
                            src="/assets/report/deductive.png"
                            className="h-[30]px w-[30px]"
                            alt="logo"
                          />
                        </div>
                        <p className="text-lg font-bold">
                          {getSkillName(findSkill("deductive"))}
                        </p>
                      </td>
                      <td>
                        <div className="flex items-center gap-5">
                          <div className="flex items-center">
                            <div className="relative w-[400px]">
                              <IoMdArrowDropdown
                                className={`text-[#F18B8B] h-8 w-8 absolute -top-4 `}
                                style={{
                                  left: `${
                                    findSkill("deductive").expectedLevel * 24 -
                                    26
                                  }%`,
                                }}
                              />

                              <input
                                type="range"
                                max="5"
                                min="1"
                                value={findSkill("deductive").level - 1}
                                disabled
                                className="appearance-none w-full  rounded-full outline-none focus:outline-none overflow-hidden z-30"
                                style={{
                                  backgroundImage: getGradientColor(
                                    findSkill("deductive").level - 1
                                  ),
                                  cursor: "pointer",
                                }}
                              />
                              <div className="absolute top-[22px] -mt-1 h-1 w-full">
                                <span
                                  className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 bg-[#66BCCE]`}
                                  style={{ left: "2%" }}
                                ></span>
                                {[1, 2, 3, 4].map((value) => (
                                  <span
                                    key={value}
                                    className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 ${
                                      findSkill("deductive").level - 1 >= value
                                        ? "bg-[#66BCCE]"
                                        : "bg-[#D9D9D9]"
                                    }`}
                                    style={{ left: `${value * 24 + 2}%` }}
                                  ></span>
                                ))}
                              </div>
                            </div>
                          </div>
                          <HoverComponent
                            svg={
                              <svg
                                width="210"
                                height="193"
                                viewBox="0 0 258 193"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                                  fill="white"
                                  stroke="#464A54"
                                  stroke-width="0.5"
                                />
                              </svg>
                            }
                            top={10}
                            left={20}
                            textStyle={"text-sm mx-3 top-8"}
                            hoverText="Дедуктив сэтгэлгээ буюу нарийвчлан дүгнэх чадвар нь батлагдсан онол, фактуудыг харгалзан дүгнэх, гаргалгаа гаргах чадвар юм."
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 flex items-center pe-20">
                        <div className="w-10">
                          <img
                            src="/assets/report/memory.png"
                            className="h-[30]px w-[30px]"
                            alt="logo"
                          />
                        </div>
                        <p className="text-lg font-bold">
                          {getSkillName(findSkill("workingMemory"))}
                        </p>
                      </td>
                      <td>
                        <div className="flex items-center gap-5">
                          <div className="flex items-center">
                            <div className="relative w-[400px]">
                              <IoMdArrowDropdown
                                className={`text-[#F18B8B] h-8 w-8 absolute -top-4 `}
                                style={{
                                  left: `${
                                    findSkill("workingMemory").expectedLevel *
                                      24 -
                                    26
                                  }%`,
                                }}
                              />
                              <p className="absolute text-base -bottom-5 -left-5">
                                Бага
                              </p>
                              <p className="absolute text-base -bottom-5 -right-5">
                                Өндөр
                              </p>
                              <input
                                type="range"
                                max="5"
                                min="1"
                                value={findSkill("workingMemory").level - 1}
                                disabled
                                className="appearance-none w-full  rounded-full outline-none focus:outline-none overflow-hidden z-30"
                                style={{
                                  backgroundImage: getGradientColor(
                                    findSkill("workingMemory").level - 1
                                  ),
                                  cursor: "pointer",
                                }}
                              />
                              <div className="absolute top-[22px] -mt-1 h-1 w-full">
                                <span
                                  className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 bg-[#66BCCE]`}
                                  style={{ left: "2%" }}
                                ></span>
                                {[1, 2, 3, 4].map((value) => (
                                  <span
                                    key={value}
                                    className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 ${
                                      findSkill("workingMemory").level - 1 >=
                                      value
                                        ? "bg-[#66BCCE]"
                                        : "bg-[#D9D9D9]"
                                    }`}
                                    style={{ left: `${value * 24 + 2}%` }}
                                  ></span>
                                ))}
                              </div>
                            </div>
                          </div>
                          <HoverComponent
                            svg={
                              <svg
                                width="210"
                                height="193"
                                viewBox="0 0 258 193"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                                  fill="white"
                                  stroke="#464A54"
                                  stroke-width="0.5"
                                />
                              </svg>
                            }
                            top={10}
                            left={20}
                            textStyle={"text-sm mx-3 top-14"}
                            hoverText="Ажлын санах ой нь өөрийн ой санамжид мэдээллийг түр хадгалах, тэдгээрийг боловсруулах чадвар юм."
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="flex justify-between items-start mx-10 mt-20">
                <div>
                  <p className="text-lg text-[#324D72] font-bold text-center">
                    Оюуны чадварын хувьд <br /> нийт тоглогчидтой харьцуулахад:
                  </p>
                  <div className="flex items-center gap-10 my-3">
                    <p>MPI оноо - {formattedmpi}</p>
                    <p>
                      Дүгнэлт:
                      {(() => {
                        if (i18n.language === "mn") {
                          return report?.ilevel.giiName;
                        } else if (i18n.language === "rn") {
                          return (
                            <p>
                              {console.log("asdasd")}
                              {report?.ilevel.ruGiName}
                            </p>
                          );
                        } else {
                          switch (report?.ilevel?.giiLvl) {
                            case 1:
                              return "Unsatisfactory";
                            case 2:
                              return "Needs Improvement";
                            case 3:
                              return "Satisfactory";
                            case 4:
                              return "Good";
                            case 5:
                              return "Excellent";
                            default:
                              return "Default Name";
                          }
                        }
                      })()}{" "}
                      ({report?.ilevel?.giiLvl})
                    </p>
                  </div>
                  <NormalDistributionChart targetValue={formattedmpi} />
                  <p className="mt-1 text-xs">
                    *{report.assPlayer?.player?.name} нийт ажил горилогчдын{" "}
                    {report.mpiPercent.toFixed(2)}% аас илүү үзxүүлэлттэй байна
                  </p>
                </div>
                <div className="me-[100px]">
                  <p className="text-lg w-[202px] text-[#324D72] font-bold text-center mb-10">
                    Оюуны чадварын хувьд нийцэж буй үзүүлэлт
                  </p>
                  <div>
                    <div className="relative ms-14">
                      <div
                        className={`absolute top-[30%] ${
                          Number(report.iqPercentage) === 100
                            ? "left-[10%]"
                            : "left-[15%]"
                        }`}
                      >
                        <p className="text-4xl font-bold bottom-percent">
                          {report.iqPercentage.toFixed(0)}%
                        </p>
                      </div>
                      <div style={{ height: "100px", width: "100px" }}>
                        <Pie data={pieData} options={pieOptions} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="my-20 pb-10">
          <div className="relative w-full bg-[#D9D9D9] px-5 py-2 flex items-center bg-opacity-30 gap-2 mt-5 mb-8">
            <HoverComponent
              svg={
                <svg
                  width="411"
                  height="225"
                  viewBox="0 0 411 225"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.24029 16.9481V16.919L4.23364 16.8908L0.343225 0.366924L24.6664 7.83639L24.7023 7.8474H24.7398H410.75V224.75H4.24029V16.9481Z"
                    fill="white"
                    stroke="#464A54"
                    strokeWidth="0.5"
                  />
                </svg>
              }
              textStyle={"ms-4 text-base top-10"}
              top={40}
              left={40}
              hoverText="Метаког метриксийн ур чадварын үзүүлэлтүүд нь ажлын байранд
              шаардлагатай зан үйл юм. Аливаа асуудлыг шийдвэрлэхэд хүний тархи
              ямар дэс дараалалтай ажилладгийг баталсан судалгаануудын үр дүн"
            />
            <p className="text-xl font-bold bottom-Space">
              2-р түвшний ур чадварын үзүүлэлтүүд
            </p>
          </div>
          <div className="flex justify-center">
            <table className="ms-10">
              <tbody>
                <tr>
                  <td className="py-2 flex items-center pe-20">
                    <div className="w-10">
                      <img
                        src="/assets/report/critical-thinking.png"
                        className="h-[30]px w-[30px]"
                        alt="logo"
                      />
                    </div>
                    <p className="text-lg font-bold">
                      {getSkillName(findSkill("criticalThinking"))}
                    </p>
                  </td>
                  <td>
                    <div className="flex items-center gap-5">
                      <div className="flex items-center">
                        <div className="relative w-[400px]">
                          <IoMdArrowDropdown
                            className={`text-[#F18B8B] h-8 w-8 absolute -top-4`}
                            style={{
                              left: `${
                                findSkill("criticalThinking").expectedLevel *
                                  24 -
                                26
                              }%`,
                            }}

                            // className={`text-[#F18B8B] h-8 w-8 absolute -top-4  left-10`}
                          />

                          <input
                            type="range"
                            max="5"
                            min="1"
                            value={findSkill("criticalThinking").level}
                            disabled
                            className="appearance-none w-full  rounded-full outline-none focus:outline-none overflow-hidden z-30"
                            style={{
                              backgroundImage: getGradientColor(
                                findSkill("criticalThinking").level
                              ),
                              cursor: "pointer",
                            }}
                          />
                          <div className="absolute top-[22px] -mt-1 h-1 w-full">
                            <span
                              className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 bg-[#66BCCE]`}
                              style={{ left: "2%" }}
                            ></span>
                            {[1, 2, 3, 4].map((value) => (
                              <span
                                key={value}
                                className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 ${
                                  findSkill("criticalThinking").level >= value
                                    ? "bg-[#66BCCE]"
                                    : "bg-[#D9D9D9]"
                                }`}
                                style={{ left: `${value * 24 + 2}%` }}
                              ></span>
                            ))}
                          </div>
                        </div>
                      </div>
                      <HoverComponent
                        svg={
                          <svg
                            width="258"
                            height="193"
                            viewBox="0 0 258 193"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                              fill="white"
                              stroke="#464A54"
                              stroke-width="0.5"
                            />
                          </svg>
                        }
                        top={20}
                        left={12}
                        textStyle={"text-sm mx-3 top-10"}
                        hoverText="Нухацтай сэтгэлгээ нь мэдээллийн найдвартай байдал болон асуудлын шалтгаан-үр дагаварын холбоог үндэслэн шийдэл дэвшүүлж буй чадвар юм ."
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="py-2 flex items-center pe-20">
                    <div className="w-10">
                      <img
                        src="/assets/report/pattern.png"
                        className="h-[30]px w-[30px]"
                        alt="logo"
                      />
                    </div>
                    <p className="text-lg font-bold">
                      {getSkillName(findSkill("patternRecognition"))}
                    </p>
                  </td>
                  <td>
                    <div className="flex items-center gap-5">
                      <div className="flex items-center">
                        <div className="relative w-[400px]">
                          <IoMdArrowDropdown
                            className={`text-[#F18B8B] h-8 w-8 absolute -top-4 `}
                            style={{
                              left: `${
                                findSkill("patternRecognition").expectedLevel *
                                  24 -
                                26
                              }%`,
                            }}
                          />

                          <input
                            type="range"
                            max="5"
                            min="1"
                            value={findSkill("patternRecognition").level - 1}
                            disabled
                            className="appearance-none w-full  rounded-full outline-none focus:outline-none overflow-hidden z-30"
                            style={{
                              backgroundImage: getGradientColor(
                                findSkill("patternRecognition").level - 1
                              ),
                              cursor: "pointer",
                            }}
                          />
                          <div className="absolute top-[22px] -mt-1 h-1 w-full">
                            <span
                              className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 bg-[#66BCCE]`}
                              style={{ left: "2%" }}
                            ></span>
                            {[1, 2, 3, 4].map((value) => (
                              <span
                                key={value}
                                className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 ${
                                  findSkill("patternRecognition").level - 1 >=
                                  value
                                    ? "bg-[#66BCCE]"
                                    : "bg-[#D9D9D9]"
                                }`}
                                style={{ left: `${value * 24 + 2}%` }}
                              ></span>
                            ))}
                          </div>
                        </div>
                      </div>
                      <HoverComponent
                        svg={
                          <svg
                            width="210"
                            height="193"
                            viewBox="0 0 258 193"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                              fill="white"
                              stroke="#464A54"
                              stroke-width="0.5"
                            />
                          </svg>
                        }
                        top={10}
                        left={20}
                        textStyle={"text-sm mx-3 top-10"}
                        hoverText="Хэв таних гэдэг нь гадна орчноос ирсэн мэдээллийг өөрийн санах ойтой нийцүүлэн дүгнэх ур чадвар юм."
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="py-2 flex items-center pe-20">
                    <div className="w-10">
                      <img
                        src="/assets/report/decision-making.png"
                        className="h-[30]px w-[30px]"
                        alt="logo"
                      />
                    </div>
                    <p className="text-lg font-bold">
                      {getSkillName(findSkill("decisionMaking"))}
                    </p>
                  </td>
                  <td>
                    <div className="flex items-center gap-5">
                      <div className="flex items-center">
                        <div className="relative w-[400px]">
                          <IoMdArrowDropdown
                            className={`text-[#F18B8B] h-8 w-8 absolute -top-4`}
                            style={{
                              left: `${
                                findSkill("decisionMaking").expectedLevel * 24 -
                                26
                              }%`,
                            }}
                          />

                          <input
                            type="range"
                            max="5"
                            min="1"
                            value={findSkill("decisionMaking").level - 1}
                            disabled
                            className="appearance-none w-full  rounded-full outline-none focus:outline-none overflow-hidden z-30"
                            style={{
                              backgroundImage: getGradientColor(
                                findSkill("decisionMaking").level - 1
                              ),
                              cursor: "pointer",
                            }}
                          />
                          <div className="absolute top-[22px] -mt-1 h-1 w-full">
                            <span
                              className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 bg-[#66BCCE]`}
                              style={{ left: "2%" }}
                            ></span>
                            {[1, 2, 3, 4].map((value) => (
                              <span
                                key={value}
                                className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 ${
                                  findSkill("decisionMaking").level - 1 >= value
                                    ? "bg-[#66BCCE]"
                                    : "bg-[#D9D9D9]"
                                }`}
                                style={{ left: `${value * 24 + 2}%` }}
                              ></span>
                            ))}
                          </div>
                        </div>
                      </div>
                      <HoverComponent
                        svg={
                          <svg
                            width="210"
                            height="193"
                            viewBox="0 0 258 193"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                              fill="white"
                              stroke="#464A54"
                              stroke-width="0.5"
                            />
                          </svg>
                        }
                        top={10}
                        left={20}
                        textStyle={"text-sm mx-3 top-10"}
                        hoverText="Олон төрлийн боломжуудыг үнэлж, мэдээллүүдийг задлан шинжилснээр оновчтой шийдлийг сонгох чадвар юм."
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="py-2 flex items-center pe-20">
                    <div className="w-10">
                      <img
                        src="/assets/report/learning.png"
                        className="h-[30]px w-[30px]"
                        alt="logo"
                      />
                    </div>
                    <p className="text-lg font-bold">
                      {getSkillName(findSkill("learningRate"))}
                    </p>
                  </td>
                  <td>
                    <div className="flex items-center gap-5">
                      <div className="flex items-center">
                        <div className="relative w-[400px]">
                          <IoMdArrowDropdown
                            className={`text-[#F18B8B] h-8 w-8 absolute -top-4 `}
                            style={{
                              left: `${
                                findSkill("learningRate").expectedLevel * 24 -
                                26
                              }%`,
                            }}
                          />

                          <input
                            type="range"
                            max="5"
                            min="1"
                            value={findSkill("learningRate").level - 1}
                            disabled
                            className="appearance-none w-full  rounded-full outline-none focus:outline-none overflow-hidden z-30"
                            style={{
                              backgroundImage: getGradientColor(
                                findSkill("learningRate").level - 1
                              ),
                              cursor: "pointer",
                            }}
                          />
                          <div className="absolute top-[22px] -mt-1 h-1 w-full">
                            <span
                              className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 bg-[#66BCCE]`}
                              style={{ left: "2%" }}
                            ></span>
                            {[1, 2, 3, 4].map((value) => (
                              <span
                                key={value}
                                className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 ${
                                  findSkill("learningRate").level - 1 >= value
                                    ? "bg-[#66BCCE]"
                                    : "bg-[#D9D9D9]"
                                }`}
                                style={{ left: `${value * 24 + 2}%` }}
                              ></span>
                            ))}
                          </div>
                        </div>
                      </div>
                      <HoverComponent
                        svg={
                          <svg
                            width="210"
                            height="193"
                            viewBox="0 0 258 193"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                              fill="white"
                              stroke="#464A54"
                              stroke-width="0.5"
                            />
                          </svg>
                        }
                        top={10}
                        left={20}
                        textStyle={"text-sm mx-3 top-10"}
                        hoverText="Суралцах хурд нь аливаа даалгаврын мөн чанарыг ойлгож гүйцэтгэлээ сайжруулж буй хурдыг илтгэж буй үзүүлэлт юм."
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="py-2 flex items-center pe-20">
                    <div className="w-10">
                      <img
                        src="/assets/report/planning.png"
                        className="h-[30]px w-[30px]"
                        alt="logo"
                      />
                    </div>
                    <p className="text-lg font-bold">
                      {getSkillName(findSkill("planning"))}
                    </p>
                  </td>
                  <td>
                    <div className="flex items-center gap-5">
                      <div className="flex items-center">
                        <div className="relative w-[400px]">
                          <IoMdArrowDropdown
                            className={`text-[#F18B8B] h-8 w-8 absolute -top-4 `}
                            style={{
                              left: `${
                                findSkill("planning").expectedLevel * 24 - 26
                              }%`,
                            }}
                          />

                          <input
                            type="range"
                            max="5"
                            min="1"
                            value={findSkill("planning").level - 1}
                            disabled
                            className="appearance-none w-full  rounded-full outline-none focus:outline-none overflow-hidden z-30"
                            style={{
                              backgroundImage: getGradientColor(
                                findSkill("planning").level - 1
                              ),
                              cursor: "pointer",
                            }}
                          />
                          <p className="absolute text-base -bottom-5 -left-5">
                            Бага
                          </p>
                          <p className="absolute text-base -bottom-5 -right-5">
                            Өндөр
                          </p>
                          <div className="absolute top-[22px] -mt-1 h-1 w-full">
                            <span
                              className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 bg-[#66BCCE]`}
                              style={{ left: "2%" }}
                            ></span>
                            {[1, 2, 3, 4].map((value) => (
                              <span
                                key={value}
                                className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 ${
                                  findSkill("planning").level - 1 >= value
                                    ? "bg-[#66BCCE]"
                                    : "bg-[#D9D9D9]"
                                }`}
                                style={{ left: `${value * 24 + 2}%` }}
                              ></span>
                            ))}
                          </div>
                        </div>
                      </div>
                      <HoverComponent
                        svg={
                          <svg
                            width="210"
                            height="193"
                            viewBox="0 0 258 193"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                              fill="white"
                              stroke="#464A54"
                              stroke-width="0.5"
                            />
                          </svg>
                        }
                        top={10}
                        left={20}
                        textStyle={"text-sm mx-3 top-6"}
                        hoverText="Ирээдүйд тодорхой зорилгод хүрэх, даалгаврыг биелүүлэх зөв арга, алхмуудыг таамаглах, зөв дараалалд оруулах чадварыг төлөвлөх чадвараар тодорхойлно."
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="flex justify-between items-start mx-10 mt-20">
            <div>
              <p className="text-lg text-[#324D72] text-center font-bold">
                Ур чадварын үзүүлэлтүүдээр <br /> нийт тоглогчидтой
                харьцуулахад:
              </p>
              <div className="flex items-center gap-10 my-3">
                <p>СI оноо - {formattedCi}</p>
                <p>
                  Дүгнэлт:
                  {(() => {
                    if (i18n.language === "mn") {
                      return report?.ilevel.ciName;
                    } else if (i18n.language === "rn") {
                      switch (report?.ilevel?.ciLvl) {
                        case 1:
                          return "Недостаточный";
                        case 2:
                          return "Удовлетворительный";
                        case 3:
                          return "Средний";
                        case 4:
                          return "Good";
                        case 5:
                          return "Высокий";
                        default:
                          return "Очень высокий";
                      }
                    } else {
                      switch (report?.ilevel?.ciLvl) {
                        case 1:
                          return "Unsatisfactory";
                        case 2:
                          return "Needs Improvement";
                        case 3:
                          return "Satisfactory";
                        case 4:
                          return "Good";
                        case 5:
                          return "Excellent";
                        default:
                          return "Default Name";
                      }
                    }
                  })()}
                  {console.log(report?.ilevel.ciName)} ({report.ilevel.ciLvl})
                </p>
              </div>
              <NormalDistributionChart targetValue={formattedCi} />
              <p className="mt-1 text-xs">
                *{report.assPlayer?.player?.name} нийт ажил горилогчдын{" "}
                {report.ciPercent.toFixed(2)}% аас илүү үзxүүлэлттэй байна
              </p>
            </div>
            <div className="me-[100px] ">
              <p className="text-lg w-[200px] text-[#324D72] font-bold text-center mb-5">
                Зан төлөвийн хувьд нийцэж буй үзүүлэлт
              </p>
              <div className="ms-10">
                <div className="relative ">
                  <div
                    className={`absolute top-[30%] ${
                      Number(report.iqPercentage) === 100
                        ? "left-[10%]"
                        : "left-[14%]"
                    }`}
                  >
                    <p className="text-4xl font-bold bottom-percent">
                      {report.personalityPercentage.toFixed(0)}%
                    </p>
                  </div>
                  <div style={{ height: "100px", width: "100px" }}>
                    <Pie data={pieData2} options={pieOptions} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="pb-20 pe-10">
          <div className="w-full bg-[#D9D9D9] px-5 py-2 flex items-center bg-opacity-30 gap-2 mt-5">
            <HoverComponent
              svg={
                <svg
                  width="411"
                  height="225"
                  viewBox="0 0 411 225"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.24029 16.9481V16.919L4.23364 16.8908L0.343225 0.366924L24.6664 7.83639L24.7023 7.8474H24.7398H410.75V224.75H4.24029V16.9481Z"
                    fill="white"
                    stroke="#464A54"
                    strokeWidth="0.5"
                  />
                </svg>
              }
              textStyle={"ms-2 text-base top-2"}
              top={40}
              left={40}
              hoverText="Зан төлөв гэдэг нь сэтгэл судлалын шинжлэх ухаанд зан араншин (темперамент) гэсэн ойлголтоос ялгаатай бөгөөд тухайн хүний удамшил, генетикийн мэдээллээс илүүтэйгээр, өсөж төлөвших нийгэмшихүйн явцад бий болсон бодол, мэдрэмжийн төлөвшсөн хэлбэрийг хэлдэг. Метаког Метриксийн зан төлөвийн үзүүлэлтүүд нь “Таван хүчин зүйлст бие хүний асуулга” аргад тулгуурласан бөгөөд дараах 3 бүлэг зан төлөвийн 9 дэд үзүүлэлтийг тодорхойлно."
            />
            <p className="text-xl font-bold bottom-Space">
              Зан төлвийн үзүүлэлтүүд
            </p>
          </div>
          <div className="flex justify-center">
            <div>
              <div className="mt-8">
                <div className="flex justify-center">
                  <div className="flex items-center w-[300px] justify-center gap-2">
                    <img src="/assets/report/curiosity.png" alt="Logo" />
                    <p className="text-center text-xl font-bold ">
                      {getSkillName(findSkill("curiosity"))}
                    </p>
                  </div>
                </div>
                <div className="flex justify-between items-center ">
                  <div className="flex items-center gap-4">
                    <p className="text-sm text-center w-[220px]">
                      Тогтвортой, сурсан дадлаа хадгалахыг эрхэмлэдэг
                    </p>
                    <HoverComponent
                      svg={
                        <svg
                          width="300"
                          height="220"
                          viewBox="0 0 258 193"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                            fill="white"
                            stroke="#464A54"
                            strokeWidth="0.5"
                          />
                        </svg>
                      }
                      top={20}
                      left={20}
                      textStyle={"text-sm mx-2 top-4"}
                      hoverText="Аливаа зүйлийг дүрэм, журам, хийж туршигдсан аргын дагуу хийж гүйцэтгэх дуртай. Өмнө нь сурсан зүйлээ давтан хийхийг чухалчилдаг буюу тухтай бүсдээ байхыг эрхэмлэдэг. Өөрийг нь сорьсон, тухтай бүсээс гаргах нөхцөл байдалтай тэмцэх, зугтах, хөлдөх зэрэг хамгаалах аргуудыг ашиглаж хандах магадлалтай."
                    />
                  </div>
                  <div className="relative">
                    <IoMdArrowDropdown
                      className={`text-[#F18B8B] h-8 w-8 absolute -top-4 `}
                      style={{
                        left: `${
                          findSkill("curiosity").expectedLevel * 24 - 26
                        }%`,
                      }}
                    />
                    <input
                      type="range"
                      max="5"
                      min="1"
                      value={findSkill("curiosity").level}
                      disabled
                      className="custom-range w-[380px] h-[12px] appearance-none rounded-full border border-[#ABADB5]"
                      style={{
                        backgroundImage:
                          "linear-gradient(to right, #7DC337, white, #F5BE6C)",
                      }}
                    />
                  </div>
                  <div className="flex items-center gap-4 ">
                    <HoverComponent
                      svg={
                        <svg
                          width="280"
                          height="220"
                          viewBox="0 0 258 193"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                            fill="white"
                            stroke="#464A54"
                            strokeWidth="0.5"
                          />
                        </svg>
                      }
                      top={20}
                      left={20}
                      textStyle={"text-sm mx-2 top-4"}
                      hoverText="Аливаа үүрэг даалгаврыг гүйцэтгэхдээ бүтээлч, шинэлэг байдлыг эн тэргүүнд тавьдаг бөгөөд шинэ зүйлс сурах, туршиж үзэх, санаа уралдуулах зэрэгт дуртай. Тогтсон зүйлээ удаан хугацаанд хийхээс амархан уйдах, туйлбаргүй байх зэрэг туйлшрах шинжүүд илрэх боломжтой."
                    />
                    <p className="text-sm text-center w-[220px]">
                      Таамаглаагүй өөрчлөлтөд нээлттэй ханддаг
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-8">
                <div className="flex justify-center ">
                  <div className="flex items-center w-[300px] gap-2 justify-center">
                    <img
                      src="/assets/report/risk-taking.png"
                      className="w-10 h-10"
                      alt="Logo"
                    />
                    <p className="text-center text-xl font-bold ">
                      {getSkillName(findSkill("riskTaking"))}
                    </p>
                  </div>
                </div>
                <div className="flex justify-between items-center ">
                  <div className="flex items-center gap-4">
                    <p className="text-sm text-center w-[220px]">
                      Аливаад болгоомжтой, няхуур ханддаг
                    </p>
                    <HoverComponent
                      svg={
                        <svg
                          width="280"
                          height="220"
                          viewBox="0 0 258 193"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                            fill="white"
                            stroke="#464A54"
                            strokeWidth="0.5"
                          />
                        </svg>
                      }
                      top={20}
                      left={20}
                      textStyle={"text-sm mx-2 top-4"}
                      hoverText="Аливаад няхуур, эрсдэлийг тооцоолж харах чадвартай бөгөөд зарим талаар гутранги хандлага ажиглагдах магадлалтай. Түгшүүр эсвэл догдлол зэрэг сэтгэл хөдлөлийн хэт туйлширсан байдлаас илүү түшигтэй, ул суурьтай, тогтвортой байдлыг чухалчилдаг. Зарим талаар хэт санаа зовомтгой шинж илрэх боломжтой."
                    />
                  </div>
                  <div className="relative">
                    <IoMdArrowDropdown
                      className={`text-[#F18B8B] h-8 w-8 absolute -top-4 `}
                      style={{
                        left: `${
                          findSkill("riskTaking").expectedLevel * 24 - 26
                        }%`,
                      }}
                    />
                    <input
                      type="range"
                      max="5"
                      min="1"
                      value={findSkill("riskTaking").level}
                      disabled
                      className="w-[380px] h-[12px] appearance-none rounded-full border border-[#ABADB5]"
                      style={{
                        backgroundImage:
                          "linear-gradient(to right, #7DC337, white, #F5BE6C)",
                      }}
                    />
                  </div>
                  <div className="flex items-center gap-4">
                    <HoverComponent
                      svg={
                        <svg
                          width="280"
                          height="220"
                          viewBox="0 0 258 193"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                            fill="white"
                            stroke="#464A54"
                            strokeWidth="0.5"
                          />
                        </svg>
                      }
                      top={20}
                      left={20}
                      textStyle={"text-xs mx-2 top-4"}
                      hoverText="Аливаа шийдвэрийг гаргахдаа шинэлэг, сорилттой талыг харгалзан үздэг бөгөөд нийтлэг туршигдсан аргыг илүү сайжруулах, үр дүн гаргахыг эрмэлздэг. Түүнчлэн олонхын эсэргүүцэлтэй нүүр тулахаас айдаггүй, зоригтой. Хэдийгээр зоригтой нь сайн хэдий ч ерөнхийлөн дүгнэх, нарийвчлан дүгнэх зэрэг оюун ухааны чадвар доогуур байвал ажлын байранд алдаа гаргах, эрсдэлийг дутуу тооцох магадлалыг нэмэгдүүлнэ."
                    />
                    <p className="text-sm text-center w-[220px]">
                      Хязгаарлагдмал мэдээлэлтэйгээр боломжийг ашиглах чадвартай
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-8">
                <div className="flex justify-center">
                  <div className="flex items-center w-full gap-2 justify-center">
                    <img
                      src="/assets/report/adaptability.png"
                      className="w-10 h-10"
                      alt="Logo"
                    />
                    <p className="text-center text-xl font-bold">
                      {getSkillName(findSkill("adaptability"))}
                    </p>
                  </div>
                </div>
                <div className="flex justify-between items-center ">
                  <div className="flex items-center gap-4">
                    <p className="text-sm text-center w-[220px]">
                      Тогтвортой орчин нөхцөлд тууштай ажиллах талтай
                    </p>
                    <HoverComponent
                      svg={
                        <svg
                          width="300"
                          height="220"
                          viewBox="0 0 258 193"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                            fill="white"
                            stroke="#464A54"
                            strokeWidth="0.5"
                          />
                        </svg>
                      }
                      top={20}
                      left={20}
                      textStyle={"text-sm mx-2 top-4"}
                      hoverText="Өөрчлөлт, хувьслыг эрсдэлтэй нөхцлөөр хүлээн авдаг бөгөөд бүх зүйлсийг тодорхой бөгөөд дүрэм, журмын дагуу хэрэгжүүлэхийг эрхэмлэдэг. Тодорхой бус, замбараагүй нөхцөл байдлыг гартаа авч, шийдвэрлэх алхмуудыг төлөвлөдөг байх магадлалтай. Тодорхой бус байдал удаан үргэлжилвэл хүчтэй сөрөг сэтгэл хөдлөлүүд илрэх боломжтой."
                    />
                  </div>
                  <div className="relative">
                    <IoMdArrowDropdown
                      className={`text-[#F18B8B] h-8 w-8 absolute -top-4 `}
                      style={{
                        left: `${
                          findSkill("adaptability").expectedLevel * 24 - 26
                        }%`,
                      }}
                    />
                    <input
                      type="range"
                      max="5"
                      min="1"
                      value={findSkill("adaptability").level}
                      disabled
                      className="w-[380px] h-[12px] appearance-none rounded-full border border-[#ABADB5]"
                      style={{
                        backgroundImage:
                          "linear-gradient(to right, #7DC337, white, #F5BE6C)",
                      }}
                    />
                  </div>
                  <div className="flex items-center gap-4">
                    <HoverComponent
                      svg={
                        <svg
                          width="300"
                          height="240"
                          viewBox="0 0 258 193"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                            fill="white"
                            stroke="#464A54"
                            strokeWidth="0.5"
                          />
                        </svg>
                      }
                      top={20}
                      left={20}
                      textStyle={"text-sm mx-2 top-4"}
                      hoverText="Өөрчлөгдөж буй нөхцөл байдлын хэрэгцээ, шаардлагыг ойлгож, түүнд хурдан хугацаанд хариу үйлдэл үзүүлж, өөрчлөгдөх чадвартай. Аливаа нөхцөл байдалд тохируулан бодол, сэтгэл хөдлөл, үйлдлээ өөрчлөн асуудлыг шийдвэрлэх чадвартай хэдий ч туушгүй байх, хэт хувьсамтгай байх нь нөхцөл байдалд сөргөөр нөлөөлөх, нийгэмд эзлэх байр суурьт муугаар нөлөөлөх боломжтой."
                    />
                    <p className="text-sm text-center w-[220px]">
                      Таамаглаагүй өөрчлөлтөд нээлттэй ханддаг
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-8">
                <div className="flex justify-center">
                  <div className="flex justify-center">
                    <div className="flex items-center w-full gap-2 justify-center">
                      <img
                        src="/assets/report/cooperative.png"
                        className="w-10 h-10"
                        alt="Logo"
                      />
                      <p className="text-center text-xl font-bold">
                        {getSkillName(findSkill("cooperative"))}
                      </p>
                    </div>
                  </div>
                </div>{" "}
                <div className="flex justify-between items-center ">
                  <div className="flex items-center gap-4">
                    <p className="text-sm text-center w-[220px]">
                      Бие даан, ганцаараа ажиллах дуртай
                    </p>
                    <HoverComponent
                      svg={
                        <svg
                          width="280"
                          height="220"
                          viewBox="0 0 258 193"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                            fill="white"
                            stroke="#464A54"
                            strokeWidth="0.5"
                          />
                        </svg>
                      }
                      top={20}
                      left={20}
                      textStyle={"text-sm mx-2 top-4"}
                      hoverText="Бие даасан шийдвэр гаргалтыг эрхэмлэдэг бөгөөд энэ нь хувийн бүтээмж, хурд зэрэгт нь сайнаар нөлөөлдөг. Багийн ажиллагаанаас илүү өрсөлдөөн нь хувь хүний хөгжилд сайнаар нөлөөлдөг гэдэг итгэл үнэмшилтэй бөгөөд хурдтай хөгжил, дэвшлийг чухалчилдаг байх магадлалтай."
                    />
                  </div>
                  <div className="relative">
                    <IoMdArrowDropdown
                      className={`text-[#F18B8B] h-8 w-8 absolute -top-4 `}
                      style={{
                        left: `${
                          findSkill("cooperative").expectedLevel * 24 - 26
                        }%`,
                      }}
                    />
                    <input
                      type="range"
                      max="5"
                      min="1"
                      value={findSkill("cooperative").level}
                      disabled
                      className="w-[380px] h-[12px] appearance-none rounded-full border border-[#ABADB5]"
                      style={{
                        backgroundImage:
                          "linear-gradient(to right, #7DC337, white, #F5BE6C)",
                      }}
                    />
                  </div>
                  <div className="flex items-center gap-4">
                    <HoverComponent
                      svg={
                        <svg
                          width="280"
                          height="220"
                          viewBox="0 0 258 193"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                            fill="white"
                            stroke="#464A54"
                            strokeWidth="0.5"
                          />
                        </svg>
                      }
                      top={20}
                      left={20}
                      textStyle={"text-sm mx-2 top-4"}
                      hoverText="Хамт олны бүтээмжтэй байдал, эерэг харилцааг чухалчилдаг бөгөөд зөрчил маргааны үед харилцан ашигтай шийдэл, хамтын хариуцлагыг эрхэмлэдэг. Бусдын оронд өөрийгөө тавин тэдний нөхцөл байдлыг ойлгож харилцдаг бөгөөд ажил мэргэжлийн хүрээнд болон хувийн хүчтэй харилцааг үүсгэхийг эрмэлздэг."
                    />
                    <p className="text-sm text-center w-[220px]">
                      Хамтарч, Багаар ажиллах дуртай{" "}
                    </p>
                  </div>
                </div>
              </div>{" "}
              <div className="mt-8">
                <div className="flex justify-center">
                  <div className="flex justify-center">
                    <div className="flex justify-center">
                      <div className="flex items-center w-full gap-2 justify-center">
                        <img
                          src="/assets/report/trust.png"
                          className="w-10 h-10"
                          alt="Logo"
                        />
                        <p className="text-center text-xl font-bold">
                          {getSkillName(findSkill("trusting"))}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between items-center ">
                  <div className="flex items-center gap-4">
                    <p className="text-sm text-center w-[220px]">
                      Амархан итгэдэггүй, сонор, хашир ханддаг
                    </p>
                    <HoverComponent
                      svg={
                        <svg
                          width="300"
                          height="240"
                          viewBox="0 0 258 193"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                            fill="white"
                            stroke="#464A54"
                            strokeWidth="0.5"
                          />
                        </svg>
                      }
                      top={20}
                      left={20}
                      textStyle={"text-sm mx-2 top-3"}
                      hoverText="Бусдад итгэхээс илүү өөрийн чадвар, бие даасан байдал, эрсдэлийг тооцох сонор, сэрэмжтэй байдалдаа итгэдэг бөгөөд таамаглаагүй нөхцөлд үргэлж бэлтгэгдсэн байхыг, шийдлийн олон хувилбартай байхыг чухалчилдаг. Бусдад итгэж чадахгүй байх, эргэлзээнд автах зэрэг нь нийгмийн харилцааг хөндий, урт хугацаанд тогтвортой бус байлгах нөхцөлийг бүрдүүлдэг."
                    />
                  </div>
                  <div className="relative">
                    <IoMdArrowDropdown
                      className={`text-[#F18B8B] h-8 w-8 absolute -top-4 `}
                      style={{
                        left: `${
                          findSkill("trusting").expectedLevel * 24 - 26
                        }%`,
                      }}
                    />
                    <input
                      type="range"
                      max="5"
                      min="1"
                      value={findSkill("trusting").level}
                      disabled
                      className="w-[380px] h-[12px] appearance-none rounded-full border border-[#ABADB5]"
                      style={{
                        backgroundImage:
                          "linear-gradient(to right, #7DC337, white, #F5BE6C)",
                      }}
                    />
                  </div>
                  <div className="flex items-center gap-4">
                    <HoverComponent
                      svg={
                        <svg
                          width="300"
                          height="220"
                          viewBox="0 0 258 193"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                            fill="white"
                            stroke="#464A54"
                            strokeWidth="0.5"
                          />
                        </svg>
                      }
                      top={20}
                      left={20}
                      textStyle={"text-xs mx-2 top-3"}
                      hoverText="Бусдад итгэж, сайн талыг, сайхан ирээдүйг төсөөлөх дуртай. Бусдын сул тал, чадахгүй байгаа зүйлст нээлттэй буюу хүлээн зөвшөөрөх хандлагаар хандаж, энэ нь эргээд өөрийг нь итгэлтэй хүн болгодог. Бусдад итгэхийг чухалчилдаг учраас бусдад хууртах, зарим тохиолдолд эрсдэлийг бүрэн тооцоогүйгээс бусдад ашиглуулах, бусад хүн итгэлийг нь алдсан, төсөөлсөн хэмжээнд нь хүрээгүйгээс үүдэн сэтгэл гонсгор байх тохиолдол их тулгардаг байх боломжтой"
                    />
                    <p className="text-sm text-center w-[220px]">
                      Бусдад итгэл хүлээлгэдэг, эергээр ханддаг
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-8">
                <div className="flex justify-center">
                  <div className="flex justify-center">
                    <div className="flex justify-center">
                      <div className="flex items-center w-full gap-2 justify-center">
                        <img
                          src="/assets/report/assertive.png"
                          className="w-10 h-10"
                          alt="Logo"
                        />
                        <p className="text-center text-xl w-[250px] font-bold">
                          {getSkillName(findSkill("assertiveness"))}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between items-center ">
                  <div className="flex items-center gap-4">
                    <p className="text-sm text-center w-[220px]">
                      Зохицомтгой, бусдын үзэл бодолд уян хатан ханддаг
                    </p>
                    <HoverComponent
                      svg={
                        <svg
                          width="280"
                          height="220"
                          viewBox="0 0 258 193"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                            fill="white"
                            stroke="#464A54"
                            strokeWidth="0.5"
                          />
                        </svg>
                      }
                      top={20}
                      left={20}
                      textStyle={"text-sm mx-2 top-6"}
                      hoverText="Нийгмийн харилцаанд бусдын үзэл бодлыг хүндэтгэх, нийцтэй байхыг илүүд үздэг. Нөгөө талаас энэ нь өөрийн ур чадвар, үнэ цэнээ дунджаас доогуур гэдэг хувийн итгэл үнэмшилтэй байх боломжтой. Аливаа шийдвэрийг гаргахдаа олон ургалч үзэлд тулгуурлан гаргадаг давуу талтай."
                    />
                  </div>
                  <div className="relative">
                    <IoMdArrowDropdown
                      className={`text-[#F18B8B] h-8 w-8 absolute -top-4`}
                      style={{
                        left: `${
                          findSkill("assertiveness").expectedLevel * 24 - 26
                        }%`,
                      }}
                    />
                    <input
                      type="range"
                      max="5"
                      min="1"
                      value={findSkill("assertiveness").level}
                      disabled
                      className="w-[380px] h-[12px] appearance-none rounded-full border border-[#ABADB5]"
                      style={{
                        backgroundImage:
                          "linear-gradient(to right, #7DC337, white, #F5BE6C)",
                      }}
                    />
                  </div>
                  <div className="flex items-center gap-4">
                    <HoverComponent
                      svg={
                        <svg
                          width="280"
                          height="220"
                          viewBox="0 0 258 193"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                            fill="white"
                            stroke="#464A54"
                            strokeWidth="0.5"
                          />
                        </svg>
                      }
                      top={20}
                      left={20}
                      textStyle={"text-sm mx-2 top-6"}
                      hoverText="Аливаа сорилт, бэрхшээл тулгарахад өөрийн нөөцдөө итгэн, даван туулах хүсэл эрмэлзэлтэй буюу бусдад шүүмжлэгдэхээс айлгүйгээр бие даан шийдвэр гаргах чадвартай. Энэхүү зан төлөвийн туйлшрал нь зөрүүд байдлаар илрэх боломжтой."
                    />
                    <p className="text-sm text-center w-[230px]">
                      Өөрийн үзэл бодолд тууштай, зоримог, шийдэмгий
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-8">
                <div className="flex justify-center">
                  <div className="flex justify-center">
                    <div className="flex justify-center">
                      <div className="flex items-center w-full gap-2 justify-center">
                        <img
                          src="/assets/report/goal-setting.png"
                          className="w-10 h-10"
                          alt="Logo"
                        />
                        <p className="text-center text-xl font-bold">
                          {getSkillName(findSkill("consistence"))}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between items-center ">
                  <div className="flex items-center gap-4">
                    <p className="text-sm text-center w-[220px]">
                      Олон шинэ зүйлсийг турших дуртай
                    </p>
                    <HoverComponent
                      svg={
                        <svg
                          width="300"
                          height="220"
                          viewBox="0 0 258 193"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                            fill="white"
                            stroke="#464A54"
                            strokeWidth="0.5"
                          />
                        </svg>
                      }
                      top={20}
                      left={20}
                      textStyle={"text-xs mx-2 top-4"}
                      hoverText="Аливаа нөхцөл байдалд сэтгэл хөдлөлийн илэрхийлэмж, мэдрэмжийг чухалчилдаг бөгөөд үүнийг ойлгох хугацааг өөртөө болон бусдад ихээр олгодог. Заримдаа энэ байдал нь эмзэг, сэтгэл хөдлөлд автамхай байдлаар илэрч болно. Ажил, үүргийн бүтэлгүйтэл, уналтын үед өөртөө итгэх итгэлийг нь дэмжих, алдааг нь хүлээн зөвшөөрч хөгжих орон зайг нь дэмжих орчинд удаан, тууштай байна. Мэдрэмж өндөртэй учраас аливааг бүтээлч, шинэлэг өнцгөөс харах чадвар өндөр хөгжсөн байх магадлалтай."
                    />
                  </div>
                  <div className="relative">
                    <IoMdArrowDropdown
                      className={`text-[#F18B8B] h-8 w-8 absolute -top-4`}
                      style={{
                        left: `${
                          findSkill("consistence").expectedLevel * 24 - 26
                        }%`,
                      }}
                    />
                    <input
                      type="range"
                      max="5"
                      min="1"
                      value={findSkill("consistence").level}
                      disabled
                      className="w-[380px] h-[12px] appearance-none rounded-full border border-[#ABADB5]"
                      style={{
                        backgroundImage:
                          "linear-gradient(to right, #7DC337, white, #F5BE6C)",
                      }}
                    />
                  </div>
                  <div className="flex items-center gap-4">
                    <HoverComponent
                      svg={
                        <svg
                          width="300"
                          height="220"
                          viewBox="0 0 258 193"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                            fill="white"
                            stroke="#464A54"
                            strokeWidth="0.5"
                          />
                        </svg>
                      }
                      top={20}
                      left={20}
                      textStyle={"text-xs mx-2 top-4"}
                      hoverText="Аливаа ажлын бүтэлгүйтэл, уналтаас хурдан босож, сэтгэл хөдлөлийн хувьд эргэн тогтвортой байдалдаа орох чадвар сайтай бөгөөд өөр гарц, эерэг өнцгийг олох чадвартай. Тууштай хүмүүс өөдрөг, эерэг сэтгэл хөдлөлийг бий болох аргыг хайдаг бөгөөд энэ нь заримдаа тэдний сэтгэл зүйн эрүүл мэндэд сөргөөр нөлөөлдөг. Иймд сөрөг сэтгэл хөдлөлөө зөв ойлгох, илэрхийлэх чадвар хөгжсөн эсэхийг үнэлж, хэрвээ доогуур байвал хөгжүүлэх нь чухал юм."
                    />
                    <p className="text-sm text-center w-[220px]">
                      Эхэлсэн зүйлээ дуусгахыг эрхэмлэдэг
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-8">
                <div className="flex justify-center">
                  <div className="flex justify-center">
                    <div className="flex justify-center">
                      <div className="flex items-center w-full gap-2 justify-center">
                        <img
                          src="/assets/report/consistency.png"
                          className="w-10 h-10"
                          alt="Logo"
                        />
                        <p className="text-center text-xl font-bold">
                          {getSkillName(findSkill("goalSetting"))}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between items-center ">
                  <div className="flex items-center gap-4">
                    <p className="text-sm text-center w-[220px]">
                      Бүтэц болон төлөвлөлгүйгээр туршиж үзэх ажиллах дуртай
                    </p>
                    <HoverComponent
                      svg={
                        <svg
                          width="300"
                          height="220"
                          viewBox="0 0 258 193"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                            fill="white"
                            stroke="#464A54"
                            strokeWidth="0.5"
                          />
                        </svg>
                      }
                      top={20}
                      left={20}
                      textStyle={"text-sm mx-2 top-6"}
                      hoverText="Аливаа үүрэг, даалгаврын үр дүн, эцсийн биелэлтээс илүүтэйгээр явц, процессыг чухалчилдаг. Ямарваа асуудал гүндээ, өөрөө шийдэгдэнэ гэдэг итгэл үнэмшил давамгайлдаг бөгөөд зорилгын мэдрэмж сул хөгжсөн нь төлөвлөгөө биелэхгүй байх, бүтэлгүйтэл зэргийг ч үйл явц гэж хардагтай нь холбоотой."
                    />
                  </div>
                  <div className="relative">
                    <IoMdArrowDropdown
                      className={`text-[#F18B8B] h-8 w-8 absolute -top-4 `}
                      style={{
                        left: `${
                          findSkill("planning").expectedLevel * 24 - 26
                        }%`,
                      }}
                    />
                    <input
                      type="range"
                      max="5"
                      min="1"
                      value={findSkill("planning").level}
                      disabled
                      className="w-[380px] h-[12px] appearance-none rounded-full border border-[#ABADB5]"
                      style={{
                        backgroundImage:
                          "linear-gradient(to right, #7DC337, white, #F5BE6C)",
                      }}
                    />
                  </div>
                  <div className="flex items-center gap-4">
                    <HoverComponent
                      svg={
                        <svg
                          width="300"
                          height="220"
                          viewBox="0 0 258 193"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                            fill="white"
                            stroke="#464A54"
                            strokeWidth="0.5"
                          />
                        </svg>
                      }
                      top={20}
                      left={20}
                      textStyle={"text-sm mx-2 top-6"}
                      hoverText="Аливаа ажил, үүргийг нарийвчлан төлөвлөж, түүндээ хүрэх олон төрлийн боломжуудыг таамагладаг. Эхэлснээ дуусгаж, хийхээр амласандаа хүрч, өөртөө зарчимтай бөгөөд үнэ цэнтэй дүрээр үлдэхийг эрхэмлэдэг."
                    />
                    <p className="text-sm text-center w-[220px]">
                      Маш сайн төлөвлөж, түүндээ хүрэхийг зорьдог
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div>
          <p className="text-center text-2xl font-bold pt-20">Дүгнэлт</p>
          <div className="flex items-start justify-between mt-20 px-20">
            <div>
              <p className="text-xl text-center font-bold mb-5">
                Хамгийн өндөр чадварууд
              </p>
              <div>
                {" "}
                <Bar data={Cdata2} options={coptions2} />
              </div>
            </div>
            <div>
              <p className="text-xl text-center font-bold mb-5">
                Илүү хөгжүүлэх чадварууд
              </p>
              <div>
                {" "}
                <Bar data={Cdata3} options={coptions2} />
              </div>
            </div>
          </div>
        </div>
        <div className="mx-[100px] mt-20">
          <p className="text-md pb-20">
            <span className="font-black"> Тайлбар:</span> Тоглоомын үр дүн нь
            зөвхөн хүнийг таних зорилготой бөгөөд сайн, муу гэсэн хариу
            <span className="font-black"> байхгүйг</span> анхаарна уу. Тоглоомын
            зорилго нь ажил олгогчийн зүгээс тогтоосон тухайн ажлын байранд
            тохирсон ур чадвар, зан төлөвтэй ажил горилогч хэр нийцэж буйг
            илрүүлэх юм. Аливаа чадвар, цаашлаад зан төлөвийг тодорхой орчин,
            нөхцөлд зохих түвшинд хүртэл сайжруулж, дадуулах боломжтой байдаг.
            Тоглогчид тохирсон сургалт, материалыг{" "}
            <span className="text-[#1A80E5] underline">
              <a href="metacog.mn" target="_blank">
                Хиймэл Оюун ухаан (AI)-д суурилсан хүний нөөцийн туслахтай
              </a>
            </span>{" "}
            зөвлөнө үү.
          </p>
          <div
            id="downloadButton"
            className="flex justify-end items-center gap-5 my-10 downloadBtn"
          >
            <button
              onClick={() => {
                downloadLongImage();
              }}
              className="px-6 py-2 border rounded-lg text-lg font-bold flex items-center gap-2 border-[#324D72] hover:bg-black hover:text-white"
            >
              <TfiSave className="h-5 w-5" />
              Тайлан татаж авах
            </button>
            {/* <button onClick={scrollToTop}>Scroll To Top</button> */}
            {/* <button className="px-6 py-2 border rounded-lg text-lg font-bold flex items-center gap-2 border-[#6CB7A1] hover:bg-black hover:text-white">
            <AiOutlinePrinter className="h-5 w-5" />
            Тайлан хэвлэх
          </button> */}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default NewGameData;
