import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const OnBoarding = () => {
  const [height3, setHeight3] = useState(window.innerHeight - 100);
  const { t } = useTranslation();

  return (
    <div className="relative" style={{ height: `${height3}px` }}>
      <div className="ms-10 mt-10">
        <p className="text-[#2C4360] font-semibold text-[36px]">
          {t("comingSoon.title")}
        </p>
      </div>
      <img
        className="absolute right-0 bottom-0"
        src="/comingSoonImg/onBoarding.svg"
        alt="coming soon"
      />
    </div>
  );
};

export default OnBoarding;
