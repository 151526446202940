import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaPlus } from "react-icons/fa6";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Loading from "../../components/uncommon/Loading";
import { getSurveys } from "../../services/employee.service";
import { deleteSurvey } from "../../services/survey.service";
import { MdOutlineEdit } from "react-icons/md";

const NewSurvey = () => {
  const [onGoingSurvey, setOnGoingSurvey] = useState(0);
  const [onSavedSurvey, setOnSavedSruvey] = useState(0);
  const [onEndedSurvey, setOnEndedSurvey] = useState(0);
  const [surveys, setSurveys] = useState(null);
  const [savedSurvey, setSavedSurvey] = useState(null);
  const [endedSurvey, setEndedSurvey] = useState(null);
  const [popUp, setPopUp] = useState(false);
  const [maxHeight, setMaxHeight] = useState("");
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [surveyToDeleteId, setSurveyToDeleteId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [height, setHeight] = useState(window.innerHeight - 250);
  const [height2, setHeight2] = useState(window.innerHeight / 2 - 154);
  const [surveyToDeleteName, setSurveyToDeleteName] = useState("");
  useEffect(() => {
    getSurveys()
      .then((data) => {
        setSurveys(data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("aldaaa ");
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight - 200);
      setHeight2(window.innerHeight / 2 - 100);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (loading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  const formatDateString = (dateString) => {
    const [datePart] = dateString.split(" ");

    return datePart.replace(/-/g, "/");
  };

  const handleDelete = (surveyId, surveyName) => {
    setSurveyToDeleteId(surveyId);
    setSurveyToDeleteName(surveyName); // Set the survey name

    setPopUp(true);
  };

  const handleConfirmDelete = () => {
    if (surveyToDeleteId) {
      deleteSurvey(surveyToDeleteId)
        .then(() => {
          setSurveys((prevSurveys) =>
            prevSurveys.filter((survey) => survey.surveyId !== surveyToDeleteId)
          );
          toast.success(t("toast.deleteSuccess"));
          setPopUp(false);
        })
        .catch((error) => {
          console.error("Error deleting survey:", error);
          toast.error(t("toast.deleteError"));
          setPopUp(false);
        });
    }
  };
  return (
    <div className=" min-h-screen">
      {surveys === null && endedSurvey === null && savedSurvey === null ? (
        <div className="h-screen flex items-center justify-center">
          <div className="flex flex-col items-center gap-4 pb-60">
            <img src="/ona/empty.png" alt="empty" />
            <p className="text-center text-[24px] text-[#222] font-semibold">
              Одоогоор үүсгэсэн Асуумж байхгүй байна.
            </p>
            <button
              onClick={() => {
                navigate("/company/create/survey");
              }}
              className="bg-[#324d72] rounded-xl px-4 py-2 font-semibold text-white flex items-center gap-2"
            >
              {t("Dashboard.createSurvey")} <FaPlus />
            </button>
          </div>
        </div>
      ) : (
        <div className="w-10/12 m-auto pt-11">
          <div className="flex justify-between items-center">
            <p className="text-[#222] text-2xl font-bold">
              {t("Survey.survey")}
            </p>
            <button
              onClick={() => {
                navigate("/company/create/survey");
              }}
              className=" p-2 text-left rounded-[10px] flex bg-main-blue hover:bg-[#283d5b] gap-2 items-center text-white"
            >
              {t("Survey.createSurvey")} <FaPlus />
            </button>
          </div>
          <div className={`flex items-start w-full mt-5 gap-10`}>
            <div className={`w-1/2`}>
              <p className="font-semibold text-lg text-[#222] mb-5">
                {t("Survey.surveyInProgress")}
              </p>
              <div
                className={`shadow-xl ${
                  surveys.length !== 0 ? "rounded-none" : "rounded-xl"
                }`}
                style={{ height: `${height}px`, overflowY: "auto" }}
              >
                {surveys.length !== 0 ? (
                  <div>
                    {surveys.slice().map((items) => {
                      const formattedDate = formatDateString(items.createdDate);
                      return (
                        <div
                          key={items.surveyId}
                          onMouseEnter={() => {
                            setOnGoingSurvey(items.surveyId);
                          }}
                          onMouseLeave={() => {
                            setOnGoingSurvey(0);
                          }}
                          className="w-full bg-white  rounded-xl flex items-center p-6  mb-4 relative transition-height duration-300 ease-in-out h-[141px]  shadow-lg "
                        >
                          <div className="w-full">
                            <div className="flex justify-between items-center mb-3">
                              <p
                                onClick={() => {
                                  navigate(
                                    `/company/survey/report/${items.surveyId}`
                                  );
                                }}
                                className="text-[#636466] font-semibold cursor-pointer"
                              >
                                {items.text}
                              </p>
                              <p className="text-xs text-[#636466]">
                                {formattedDate}
                              </p>
                            </div>
                            <div className="flex items-center justify-between">
                              <div>
                                <p className="text-[#636466] text-xs">
                                  {t("Survey.question")}:
                                </p>
                                <p className="text-[#222] text-xs">
                                  {items.questionCount}
                                </p>
                              </div>
                              <div>
                                <p className="text-[#636466] text-xs">
                                  {t("Survey.performance")}:
                                </p>
                                <p className="text-[#222] text-xs text-center">
                                  {isNaN(
                                    (items.finishedCount /
                                      items.employeeCount) *
                                      100
                                  )
                                    ? 0
                                    : (
                                        (items.finishedCount /
                                          items.employeeCount) *
                                        100
                                      ).toFixed(0)}{" "}
                                  %
                                </p>
                              </div>
                              <div>
                                <p className="text-[#636466] text-xs">
                                  {t("Survey.participant")}:
                                </p>
                                <p className="text-[#222] text-xs ">
                                  {items.employeeCount}
                                </p>
                              </div>
                            </div>
                            <div
                              className={`overflow-hidden ${
                                onGoingSurvey === items.surveyId
                                  ? "max-h-[40px]"
                                  : "max-h-0"
                              } transition-max-height duration-300 ease-in-out`}
                            >
                              <div className="flex items-center justify-between mt-4">
                                <p
                                  onClick={() => {
                                    navigate(
                                      `/company/survey/report/${items.surveyId}`
                                    );
                                  }}
                                  className="text-[#636466] text-xs flex items-center gap-2  cursor-pointer"
                                >
                                  Дэлгэрэнгүй
                                  <IoIosArrowForward />
                                </p>
                                <div className="flex items-center gap-2">
                                  <div
                                    onClick={() =>
                                      navigate(
                                        `/company/edit/survey/${items.surveyId}`
                                      )
                                    }
                                    className="p-1 bg-[#F3F4F8] rounded-full cursor-pointer"
                                  >
                                    <MdOutlineEdit className="text-[#636466]" />
                                  </div>
                                  <div
                                    onClick={() =>
                                      handleDelete(items.surveyId, items.text)
                                    }
                                    className="p-1 bg-[#F3F4F8] rounded-full cursor-pointer"
                                  >
                                    <svg
                                      width="10"
                                      height="12"
                                      viewBox="0 0 10 12"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M7.66732 4V10.6667H2.33398V4H7.66732ZM6.66732 0H3.33398L2.66732 0.666667H0.333984V2H9.66732V0.666667H7.33398L6.66732 0ZM9.00065 2.66667H1.00065V10.6667C1.00065 11.4 1.60065 12 2.33398 12H7.66732C8.40065 12 9.00065 11.4 9.00065 10.6667V2.66667Z"
                                        fill="#636466"
                                      />
                                    </svg>
                                  </div>

                                  {/* <div
                                    onClick={() => {
                                      navigate(
                                        `/company/survey/report/${items.surveyId}/edit`
                                      );
                                    }}
                                    className="p-1 bg-[#F3F4F8] rounded-full cursor-pointer "
                                  >
                                    <MdOutlineEdit className="text-[#636466] h-[12px] w-[12px]" />
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="h-full flex items-center justify-center">
                    <div className="flex flex-col items-center">
                      <img src="/ona/empty.png" alt="empty" />
                      <p className="text-[#1E293B] mt-2">
                        {t("Survey.noSurveys")}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="w-1/2">
              <p className="font-semibold text-lg text-[#222] mb-5 ">
                {t("Survey.savedSurveys")}
              </p>
              <div
                className={`shadow-xl ${
                  savedSurvey !== null ? "rounded-none" : "rounded-xl"
                }`}
                style={{ height: `${height2}px`, overflowY: "auto" }}
              >
                {savedSurvey !== null ? (
                  <div>
                    <div className="w-full bg-white  rounded-xl flex items-center p-6  mb-4 relative transition-height duration-300 ease-in-out h-[141px]  shadow-lg ">
                      <div className="w-full">
                        <div className="flex justify-between items-center mb-3">
                          <p className="text-[#636466] font-semibold">
                            Survey name
                          </p>
                          <p className="text-xs text-[#636466]">2023/12/21</p>
                        </div>
                        <div className="flex itesm-center justify-between">
                          <div>
                            <p className="text-[#636466] text-xs">Асуулт:</p>
                            <p className="text-[#222] text-xs">25</p>
                          </div>
                          <div>
                            <p className="text-[#636466] text-xs">Гүйцэтгэл:</p>
                            <p className="text-[#222] text-xs">-</p>
                          </div>
                          <div>
                            <p className="text-[#636466] text-xs">Оролцогч:</p>
                            <p className="text-[#222] text-xs">Бүх ажилтан</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full bg-white  rounded-xl flex items-center p-6  mb-4 relative transition-height duration-300 ease-in-out h-[141px]  shadow-lg ">
                      <div className="w-full">
                        <div className="flex justify-between items-center mb-3">
                          <p className="text-[#636466] font-semibold">
                            Survey name
                          </p>
                          <p className="text-xs text-[#636466]">2023/12/21</p>
                        </div>
                        <div className="flex itesm-center justify-between">
                          <div>
                            <p className="text-[#636466] text-xs">Асуулт:</p>
                            <p className="text-[#222] text-xs">25</p>
                          </div>
                          <div>
                            <p className="text-[#636466] text-xs">Гүйцэтгэл:</p>
                            <p className="text-[#222] text-xs">-</p>
                          </div>
                          <div>
                            <p className="text-[#636466] text-xs">Оролцогч:</p>
                            <p className="text-[#222] text-xs">Бүх ажилтан</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full bg-white  rounded-xl flex items-center p-6  mb-4 relative transition-height duration-300 ease-in-out h-[141px]  shadow-lg ">
                      <div className="w-full">
                        <div className="flex justify-between items-center mb-3">
                          <p className="text-[#636466] font-semibold">
                            Survey name
                          </p>
                          <p className="text-xs text-[#636466]">2023/12/21</p>
                        </div>
                        <div className="flex itesm-center justify-between">
                          <div>
                            <p className="text-[#636466] text-xs">Асуулт:</p>
                            <p className="text-[#222] text-xs">25</p>
                          </div>
                          <div>
                            <p className="text-[#636466] text-xs">Гүйцэтгэл:</p>
                            <p className="text-[#222] text-xs">-</p>
                          </div>
                          <div>
                            <p className="text-[#636466] text-xs">Оролцогч:</p>
                            <p className="text-[#222] text-xs">Бүх ажилтан</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className=" flex items-center justify-center">
                    <div className="flex flex-col items-center">
                      <img src="/ona/empty.png" alt="empty" />
                      <p className="text-[#1E293B] mt-2">
                        {t("Survey.noSurveys")}
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <div>
                <p className="text-lg text-[#222] font-semibold mt-5 mb-3">
                  {t("Survey.completedSurveys")}
                </p>
                <div
                  className={`shadow-xl ${
                    endedSurvey !== null ? "rounded-none" : "rounded-xl"
                  }`}
                  style={{ height: `${height2}px`, overflowY: "auto" }}
                >
                  {endedSurvey !== null ? (
                    <div>
                      <div className="w-full bg-white  rounded-xl flex items-center p-6  mb-4 relative transition-height duration-300 ease-in-out h-[141px]  shadow-lg ">
                        <div className="w-full">
                          <div className="flex justify-between items-center mb-3">
                            <p className="text-[#636466] font-semibold">
                              Survey name
                            </p>
                            <p className="text-xs text-[#636466]">2023/12/21</p>
                          </div>
                          <div className="flex itesm-center justify-between">
                            <div>
                              <p className="text-[#636466] text-xs">Асуулт:</p>
                              <p className="text-[#222] text-xs">25</p>
                            </div>
                            <div>
                              <p className="text-[#636466] text-xs">
                                Гүйцэтгэл:
                              </p>
                              <p className="text-[#222] text-xs">-</p>
                            </div>
                            <div>
                              <p className="text-[#636466] text-xs">
                                Оролцогч:
                              </p>
                              <p className="text-[#222] text-xs">Бүх ажилтан</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-full bg-white  rounded-xl flex items-center p-6  mb-4 relative transition-height duration-300 ease-in-out h-[141px]  shadow-lg ">
                        <div className="w-full">
                          <div className="flex justify-between items-center mb-3">
                            <p className="text-[#636466] font-semibold">
                              Survey name
                            </p>
                            <p className="text-xs text-[#636466]">2023/12/21</p>
                          </div>
                          <div className="flex itesm-center justify-between">
                            <div>
                              <p className="text-[#636466] text-xs">Асуулт:</p>
                              <p className="text-[#222] text-xs">25</p>
                            </div>
                            <div>
                              <p className="text-[#636466] text-xs">
                                Гүйцэтгэл:
                              </p>
                              <p className="text-[#222] text-xs">-</p>
                            </div>
                            <div>
                              <p className="text-[#636466] text-xs">
                                Оролцогч:
                              </p>
                              <p className="text-[#222] text-xs">Бүх ажилтан</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-full bg-white  rounded-xl flex items-center p-6  mb-4 relative transition-height duration-300 ease-in-out h-[141px]  shadow-lg ">
                        <div className="w-full">
                          <div className="flex justify-between items-center mb-3">
                            <p className="text-[#636466] font-semibold">
                              Survey name
                            </p>
                            <p className="text-xs text-[#636466]">2023/12/21</p>
                          </div>
                          <div className="flex itesm-center justify-between">
                            <div>
                              <p className="text-[#636466] text-xs">Асуулт:</p>
                              <p className="text-[#222] text-xs">25</p>
                            </div>
                            <div>
                              <p className="text-[#636466] text-xs">
                                Гүйцэтгэл:
                              </p>
                              <p className="text-[#222] text-xs">-</p>
                            </div>
                            <div>
                              <p className="text-[#636466] text-xs">
                                Оролцогч:
                              </p>
                              <p className="text-[#222] text-xs">Бүх ажилтан</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className=" flex items-center justify-center">
                      <div className="flex flex-col items-center">
                        <img src="/ona/empty.png" alt="empty" />
                        <p className="text-[#1E293B] mt-2">
                          {t("Survey.noSurveys")}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {popUp && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-xl px-8  w-[391px] h-[222px] flex flex-col justify-center">
            <p className="text-lg text-[#444] font-semibold text-center mb-6 px-8">
              Та “{surveyToDeleteName}”-г устгахдаа итгэлтэй байна уу?
            </p>
            <button
              onClick={handleConfirmDelete}
              className="w-full rounded-xl py-2 bg-[#2D2D2D] text-white text-sm mb-2"
            >
              Устгах
            </button>
            <button
              onClick={() => {
                setPopUp(false);
              }}
              className="w-full rounded-xl py-2 bg-[#E5E7EB] text-[#6B7280] text-sm mb-2"
            >
              Болих
            </button>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default NewSurvey;
