import { AiOutlinePlus, AiTwotoneDelete } from "react-icons/ai";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
// import { FiEdit } from "react-icons/fi";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { Link, useNavigate } from "react-router-dom";
import {
  getSurveys,
  getSurveyById,
  deleteSurvey,
} from "../../services/survey.service";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { GrEdit } from "react-icons/gr";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import Loading from "../../components/uncommon/Loading";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const DropdownMenu = ({ onEdit, onDelete }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <button
        className="dropdown-button p-2 focus:outline-none"
        onClick={toggleDropdown}
      >
        <BiDotsVerticalRounded className="text-bold" />
      </button>
      {isOpen && (
        <div className="dropdown-content flex flex-col space-y-2 absolute bg-white p-2 right-20 z-10 border border-gray-300 rounded-lg">
          <button
            className="flex items-center px-4 py-2 hover:bg-gray-100"
            onClick={onEdit}
          >
            <GrEdit className="mr-2" /> Edit
          </button>
          <button
            className="flex items-center px-4 py-2 hover:bg-gray-100"
            onClick={onDelete}
          >
            <AiTwotoneDelete className="mr-2" />
            Delete
          </button>
        </div>
      )}
    </div>
  );
};

const Survey = () => {
  const { t, i18n } = useTranslation();
  const [surveys, setSurveys] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    getSurveys()
      .then((data) => {
        setSurveys(data.data);
      })
      .catch((err) => {
        console.log("aldaaa ");
      });
    setIsLoading(false);
  }, []);

  const handleEdit = (id) => {
    navigate(`questions_edit/${id}`);
  };

  const handleDelete = (id) => {
    deleteSurvey(id)
      .then(() => {
        setSurveys((prevSurveys) =>
          prevSurveys.filter((survey) => survey.surveyId !== id)
        );
        toast.success("");
      })
      .catch((error) => {
        console.error("Error deleting survey:", error);
        toast.error(t("toast.Error"));
      });
  };

  // chart
  const data = {
    labels: ["A", "B", "C", "D", "F"],
    datasets: [
      {
        label: t("Survey.unsatisfied"),
        data: [25, 30, 50, 10, 40],
        backgroundColor: "#F18B8B",
        stack: "stack",
        barThickness: 35,
      },
      {
        label: t("Survey.normal"),
        data: [15, 10, 20, 40, 20],
        backgroundColor: "#F0CB6D",
        stack: "stack",
        barThickness: 35,
      },

      {
        label: t("Survey.satisfied"),
        data: [60, 60, 30, 50, 40],
        backgroundColor: "#55D6A0",
        stack: "stack",
        barThickness: 35,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        stacked: true,
      },
      y: {
        beginAtZero: true,
        max: 100,
        ticks: {
          callback: function (value) {
            return value + "%";
          },
          stepSize: 25,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
        reverse: true,
      },
      tooltip: {
        mode: "index",
        intersect: false,
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            label += context.parsed.y + "%";
            return label;
          },
        },
      },
    },
  };

  console.log(surveys);

  if (isLoading) {
    return (
      <div>
        <Loading />{" "}
      </div>
    );
  }

  return !surveys ? (
    <div className="m-auto w-10/12 pt-11">
      <div>
        <div className="flex justify-between items-center">
          <p className="text-2xl font-bold text-[#324D72]">
            {t("Survey.companySurvey")}
          </p>
          <Link
            to="/company/survey/questions_add"
            className="py-3 px-6 text-lg font-bold hover:bg-[#324d60]  focus:bg-[#324d60] rounded-lg bg-[#324D72] flex items-center gap-2 text-white"
          >
            Судалгаа үүсгэх <AiOutlinePlus />
          </Link>
        </div>
        <hr className="my-5" />
      </div>
      <div>
        <p className="text-xl font-black">
          Таньд одоогоор ямар нэгэн үүсгэсэн багийн судалгаа, асуулт байхгүй
          байна
        </p>
        <p className="text-lg mt-2">
          Та судалгаа, асуулт үүсгэх бол судалгаа үүсгэх гэсэн товчлуур дээр
          дарна уу.
        </p>
      </div>
    </div>
  ) : (
    <>
      <div className="m-auto w-10/12 pt-11">
        <div>
          <p className="text-2xl font-bold text-[#324D72]">
            {t("Survey.companySurvey")}
          </p>
          <hr className="my-5" />
        </div>
        {/* <div>
          <div>
            <p className="text-xl font-bold text-[#324D72] my-2">
              {t('Survey.companySatisfaction')}
            </p>
            <p className="text-base mb-5"></p>
          </div>
        </div>
        <div>
          <Bar
            style={{ backgroundColor: "#F5F5F6", padding: "20px", borderRadius: "10px" }}
            data={data}
            options={options}
          />
        </div> */}
        <div className="flex justify-between items-center mt-10 mb-10">
          <p className="text-2xl text-bold text-[#324d72] ">
            {t("Survey.surveys")}
          </p>
          <div>
            <Link
              to="/company/create/survey"
              className="py-3 px-6 text-lg font-bold hover:bg-[#324d60]  focus:bg-[#324d60] rounded-lg bg-[#324D72] flex items-center gap-2 text-white"
            >
              {t("Survey.createSurvey")} <AiOutlinePlus />
            </Link>
          </div>
        </div>
        
        <div className="grid grid-flow-col gap-4">
          {/* sudalgaa */}
          {/* <div className="grid-rows-8"> */}
          <div>
            {/* Удахгүй болох судалгаа */}
            <div>
              {/* <div className="mb-5">
                <p className="text-2xl font-bold">Удахгүй болох судалгаа</p>
              </div> */}
              {console.log("Survey = ", surveys)}
              {surveys.map((item) => {
                const createdDate = new Date(item.createdDate);
                const year = createdDate.getFullYear();
                const month = (createdDate.getMonth() + 1)
                  .toString()
                  .padStart(2, "0");
                const day = createdDate.getDate().toString().padStart(2, "0");

                return (
                  <div
                    key={item.id}
                    className="grid grid-cols-3 gap-4 bg-[#F5F5F6] mb-5 rounded-lg"
                  >
                    <div className="col-span-1">
                      <div className="py-5 mb-5 rounded-lg px-10 ">
                        <div>
                          <Link to={`report/${item.surveyId}`}>
                            <p className="text-xl font-bold text-[#324D72]">
                              {year}-{month}
                            </p>
                            <div className="flex gap-1 underline justife-center">
                              <p>{item.text} </p>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="col-span-1">
                      <div className="py-5 mb-5 rounded-lg px-10 ">
                        <div className="text-center">
                          <p className="underline">{t("Survey.progress")}</p>
                          <p className="font-black">0%</p>
                          {/* <p className="underline">судалгааны мэдээлэл</p> */}
                        </div>
                      </div>
                    </div>

                    <div className="col-span-1">
                      <div className="py-5 mb-5 rounded-lg px-10 ">
                        <div className="flex justify-center items-center">
                          <div>
                            <p className="font-bold mb-1 text-center">
                              {item.questionCount}
                            </p>
                            <p className="underline">{t("Survey.question")}</p>
                          </div>
                          <div>
                            <DropdownMenu
                              onEdit={() => handleEdit(item.surveyId)}
                              onDelete={() => handleDelete(item.surveyId)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {/* <div className="grid-rows-4 mt-20">
            <div className="bg-[#f5f5f6] rounded py-3 px-6">
              <div>
                <p className="text-lg font-bold mb-2 text-[#324d72]">
                  Асуултыг засах
                </p>
                <p className="text-[#666874] mb-2">
                  Та удахгүй болох судалгаанд хүссэн үедээ өөрчлөлт оруулах
                  боломжтой.
                </p>
              </div>
              <div>
                <Link
                  to={{}}
                  className="flex font-bold text-[#324d72] hover:text-[#000] underline items-center"
                >
                  <BiEdit className="text-[#FF9D72] hover:text-[#FF9D60] " />
                  асуултыг засах
                </Link>
              </div>
            </div>
            <div className="mt-3">
              <button className="text-[#FF9D72] py-1 px-3 underline  hover:text-white hover:rounded  hover:bg-[#FF9D72]">
                Багийг идэвхгүй болгох
              </button>
            </div>
          </div> */}
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Survey;
