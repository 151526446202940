import { useEffect, useState } from "react";
import Unity, { UnityContext } from "react-unity-webgl";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { findBurnoutPlayer } from "../../services/burnout.service";

import "./../../App.css";
import { createBurnoutScore } from "../../services/game.service";
import { useTranslation } from "react-i18next";
import { LinearProgress, linearProgressClasses } from "@mui/material";
import { styled } from "@mui/material/styles";
import { text } from "@fortawesome/fontawesome-svg-core";


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor:
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === "light" ? "#30e83c" : "#30e880",
    },
}));

const unityContext = new UnityContext({
    productName: "Test",
    companyName: "Metacog",

    loaderUrl: "../../../../unity/burnout/BurnoutBuild.loader.js",
    dataUrl: "../../../../unity/burnout/BurnoutBuild.data",
    frameworkUrl: "../../../../unity/burnout/BurnoutBuild.framework.js",
    codeUrl: "../../../../unity/burnout/BurnoutBuild.wasm",
});

const games = [
    "COLORS",
    "SLEEPQ",
    "STROOP",
    "HEALTHQ",
    "UMBRELLA",
    "ADAPTQ",
    "ZUMAQ",
];

function BurnoutFlow() {
    let navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const { textUrl } = useParams();
    const [orientation, setOrientation] = useState('portrait');
    const [progress, setProgress] = useState(0);
    const [isSurvey, setIsSurvey] = useState(false);
    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
    const [surveyUrl, setSurveyUrl] = useState("")
    const [isRotate, setIsRotate] = useState(false)

    const updateOrientation = () => {
        setOrientation(window.innerWidth > window.innerHeight ? 'landscape' : 'portrait');
        setViewportHeight(window.innerHeight);
        if (window.innerWidth > window.innerHeight) {
            setIsRotate(true)
        }
    };

    useEffect(function () {
        window.alert = function () { };
        window.addEventListener('resize', updateOrientation);
        updateOrientation();

        let surveyUrltext;
        let playerId;


        findBurnoutPlayer(textUrl).then(val => {
            const { data } = val;
            if (!data.id) {
                navigate("/notfound")
            }
            console.log("data = ", data)
            if ((data.companyId === 193) && !data.age) {
                navigate("/company/burnout/questions/permission", {
                    state: {
                        backUrl: "/bo/" + textUrl,
                        id: data.id
                    }
                })
                return () => unityContext.removeEventListener("progress");
            }

            setSurveyUrl(data.surveyUrl);
            surveyUrltext = data.surveyUrl;
            playerId = data.id
        })

        unityContext.on("progress", (progression) => {
            if (progression === 1) {
                setTimeout(() => {
                    setProgress(progression * 100);
                }, 3000);
            } else {
                setProgress(progression * 100);
            }
            unityContext.send("MetaCog", "ChangeLang", '0');

        });

        let st = 0;
        let gameScores = [];

        unityContext.on("GameOver1", async function (result) {
            gameScores = [...gameScores,
            {
                burnoutPlayerId: parseInt(playerId),
                gameCode: games[st],
                values: result,
            }];
            st++;

            if (st < 7)
                unityContext.send("MetaCog", "GameLang", `${st},0`);
            else {
                await createBurnoutScore(gameScores).then(a => {
                })
                // window.location.href = surveyUrltext;
                navigate(`/congratz`);
            }
        });
        
        return () => window.removeEventListener('resize', updateOrientation);
    }, []);

    return (
        <div style={{ width: '100vw', height: `${viewportHeight}px` }}>
            {progress < 100 && (orientation !== 'portrait' || isRotate === true) ? (
                <div
                    className={
                        " top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-screen h-screen bg-[#f6f7f8] z-20 fixed"
                    }
                >
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">
                        <div className=" text-center text-lg">
                            {t('GameFlow.loading')}
                            <div className="font-bold">{progress.toFixed(2)}%</div>{" "}
                        </div>
                        <div style={{ width: 350 }} className="mt-4">
                            <BorderLinearProgress variant="determinate" value={progress} />
                        </div>
                    </div>
                </div>
            ) : null}
            {
                orientation === 'portrait' && isRotate === false
                    ? <div className="flex flex-col items-center justify-center h-full w-full">
                        <img src="/assets/rotate.webp" alt="" />
                        <p>Сайн байна уу</p>
                        <p>Та утсаа эргүүлнэ үү!</p>
                        <p>Баярлалаа</p>
                    </div>
                    : <Unity style={orientation === "portrait" && isRotate === true ? { marginTop: '40%', width: '100%', height: '50%' } : { width: '100%', height: '100%' }} unityContext={unityContext} />
            }
        </div>
    )
}
export default BurnoutFlow;
