import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaMagnifyingGlass } from "react-icons/fa6";
import SendedFeedback from "./feedback/SendedFeedback";
import { useNavigate } from "react-router-dom";
import OnboardFeedback from "./feedback/OnboardFeedback";
import {
  getCompanyContractEmployee,
  getCompanyNewEmployee,
  getCompanyOfficialEmployee,
} from "../../services/employee.service";
import Loading from "../../components/uncommon/Loading";
import { IoIosArrowForward } from "react-icons/io";

const Feedback = () => {
  const [height, setHeight] = useState(window.innerHeight - 64);
  const [activeTable, setActiveTable] = useState("report");
  const [mainEmployee, setMainEmployees] = useState(null);
  const [contractEmployee, setContractEmployees] = useState(null);
  const [newEmployee, setNewEmployees] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [hover, setHover] = useState(0);
  const [id, setId] = useState(null);
  const coming = true;
  const navigate = useNavigate();

  useEffect(() => {
    getCompanyOfficialEmployee().then((data) => {
      setMainEmployees(data.data);
    });
    getCompanyContractEmployee().then((data) => {
      setContractEmployees(data.data);
    });
    getCompanyNewEmployee().then((data) => {
      setNewEmployees(data.data);
    });
  }, []);

  useEffect(() => {
    if (mainEmployee && mainEmployee.length > 0) {
      setId(mainEmployee[0].id);
    } else if (contractEmployee && contractEmployee.length > 0) {
      setId(contractEmployee[0].id);
    } else if (newEmployee && newEmployee.length > 0) {
      setId(newEmployee[0].id);
    } else {
      setId(null);
    }
  }, [mainEmployee, contractEmployee, newEmployee]);

  const handleTableChange = (table) => {
    setActiveTable(table);
    navigate(`/company/feedback/${table}`);
  };
  const renderActiveTable = () => {
    switch (activeTable) {
      case "report":
        return <SendedFeedback id={id} />;
      case "onboard":
        return <OnboardFeedback />;
      default:
        return null;
    }
  };

  const filterEmployees = (employees) => {
    return employees.filter((employee) =>
      `${employee.firstName} ${employee.lastName}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
  };

  if (
    mainEmployee === null ||
    contractEmployee === null ||
    newEmployee === null
  ) {
    return <Loading />;
  }

  return (
    <div className="w-full flex">
      <div
        style={{ height: `${height}px` }}
        className="w-[20%]  overflow-y-scroll border-r border-[#E2E8F0] px-1 py-2"
      >
        <div className="flex relative">
          <div className="absolute top-1/2 left-3 transform  -translate-y-1/2 z-10">
            <FaMagnifyingGlass className="text-[#64748B] opacity-100" />
          </div>
          <input
            className="rounded-xl text-sm opacity-80 w-full bg-[#F9F9F9] border-none ps-8"
            placeholder="Ажилтны нэрийг бичнэ үү."
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)} // Update search term
          />
        </div>
        {mainEmployee.length !== 0 && (
          <div className="mt-4">
            <p className="text-[#293951] text-sm mb-2">Үндсэн ажилтан</p>
            <div
              className={`${
                contractEmployee.length === 0 && newEmployee.length === 0
                  ? "h-[80%]"
                  : contractEmployee.length === 0 || newEmployee.length === 0
                  ? "h-[300px]"
                  : "h-[300px]"
              } overflow-y-scroll`}
            >
              {filterEmployees(mainEmployee).map((items) => {
                return (
                  <div
                    key={items.id}
                    onClick={() => {
                      setHover(0);
                      setId(items.id);
                    }}
                    onMouseEnter={() => {
                      if (id !== items.id) {
                        setHover(items.id);
                      }
                    }}
                    onMouseLeave={() => {
                      setHover(0);
                    }}
                    className={`flex items-center justify-between gap-2 border cursor-pointer  ${
                      id === items.id
                        ? "bg-[#EEF2FF] border-[#AAB0C5]"
                        : "bg-[#F9F9F9] border-[#F9F9F9]"
                    } p-2 mb-2 rounded-xl hover:bg-[#F3F4F8]`}
                  >
                    <div className="flex items-center gap-2">
                      <div className="w-[30px] h-[30px]">
                        <img
                          className="w-[30px] h-[30px]"
                          src="/user.svg"
                          alt="img"
                        />
                      </div>
                      <div>
                        <p className="text-xs text-[#1E293B] font-semibold">
                          {items.lastName} {items.firstName}
                        </p>
                        <p className="text-[#64748B] text-xs">
                          {items.position}
                        </p>
                      </div>
                    </div>
                    <div className="w-[20px]">
                      {hover === items.id && (
                        <IoIosArrowForward className="text-xl text-[#2C4360] opacity-70" />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {contractEmployee.length !== 0 && (
          <div className="mt-4">
            <p className="text-[#293951] text-sm mb-2">Гэрээт ажилтан</p>
            <div
              className={`${
                newEmployee.length === 0 && mainEmployee.length === 0
                  ? "h-[80%]"
                  : newEmployee.length === 0 || mainEmployee.length === 0
                  ? "h-[300px]"
                  : "h-[300px]"
              } overflow-y-scroll`}
            >
              {filterEmployees(contractEmployee).map((items) => {
                return (
                  <div
                    onClick={() => {
                      setHover(0);
                      setId(items.id);
                    }}
                    onMouseEnter={() => {
                      if (id !== items.id) {
                        setHover(items.id);
                      }
                    }}
                    onMouseLeave={() => {
                      setHover(0);
                    }}
                    className={`flex items-center justify-between gap-2 border border-[#F9F9F9] ${
                      id === items.id
                        ? "bg-[#EEF2FF] border-[#AAB0C5]"
                        : "bg-[#F9F9F9]"
                    } p-2 mb-2 rounded-xl hover:bg-[#F3F4F8]`}
                  >
                    <div className="flex items-center gap-2">
                      {/* <div className="w-[30px] h-[30px]">
                        <img
                          className="w-[30px] h-[30px]"
                          src="/user.svg"
                          alt="img"
                        />
                      </div> */}
                      <div>
                        <p className="text-xs text-[#1E293B] font-semibold">
                          {items.lastName} {items.firstName}
                        </p>
                        <p className="text-[#64748B] text-xs">
                          {items.position}
                        </p>
                      </div>
                    </div>
                    <div className="w-[20px]">
                      {hover === items.id && (
                        <IoIosArrowForward className="text-xl text-[#2C4360] opacity-70" />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {newEmployee.length !== 0 && (
          <div className="mt-4">
            <p className="text-[#293951] text-sm mb-2">Шинээр нэмэгдэж буй</p>
            <div
              className={`${
                contractEmployee.length === 0 && mainEmployee.length === 0
                  ? "h-[80%]"
                  : contractEmployee.length === 0 || mainEmployee.length === 0
                  ? "h-[300px]"
                  : "h-[300px]"
              } overflow-y-scroll`}
            >
              {filterEmployees(newEmployee).map((items) => {
                return (
                  <div
                    onClick={() => {
                      setHover(0);
                      setId(items.id);
                    }}
                    onMouseEnter={() => {
                      if (id !== items.id) {
                        setHover(items.id);
                      }
                    }}
                    onMouseLeave={() => {
                      setHover(0);
                    }}
                    className={`flex items-center justify-between gap-2 border border-[#F9F9F9] ${
                      id === items.id
                        ? "bg-[#EEF2FF] border-[#AAB0C5]"
                        : "bg-[#F9F9F9]"
                    } p-2 mb-2 rounded-xl hover:bg-[#F3F4F8]`}
                  >
                    <div className="flex items-center gap-2">
                      {/* <div className="w-[30px] h-[30px]">
                        <img
                          className="w-[30px] h-[30px]"
                          src="/user.svg"
                          alt="img"
                        />
                      </div> */}
                      <div>
                        <p className="text-xs text-[#1E293B] font-semibold">
                          {items.lastName} {items.firstName}
                        </p>
                        <p className="text-[#64748B] text-xs">
                          {items.position}
                        </p>
                      </div>
                    </div>
                    <div className="w-[20px]">
                      {hover === items.id && (
                        <IoIosArrowForward className="text-xl text-[#2C4360] opacity-70" />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      <div className="w-[80%] px-4">
        <div className="border-b flex items-center gap-4 pt-2 pb-4">
          {/* <button onClick={() => handleTableChange("")}>
            <p className={`text-[#666874] text-sm`}>Overview</p>
          </button> */}
          <button onClick={() => handleTableChange("report")}>
            <p
              className={` text-sm ${
                activeTable === "report"
                  ? "text-[#324D72] border-b border-[#324D72]"
                  : "text-[#666874]"
              }`}
            >
              Feedback
            </p>
          </button>
          {/* <button onClick={() => handleTableChange("onboard")}>
            <p
              className={`${
                activeTable === "onboard"
                  ? "text-[#324D72] border-b border-[#324D72]"
                  : "text-[#666874]"
              } text-sm`}
            >
              Onboarding
            </p>
          </button> */}
          {/* <button onClick={() => handleTableChange("")}>
            <p className={`text-[#666874] text-sm`}>Survey</p>
          </button> */}
        </div>
        <div>{renderActiveTable()}</div>
      </div>
    </div>
  );
};
export default Feedback;
