import React, { useEffect, useState } from "react";
import {
  getCompanyEmployee,
  getDepartments,
} from "../../../services/employee.service";
import Loading from "../../../components/uncommon/Loading";
import { FaRegBookmark, FaSpinner } from "react-icons/fa6";
import { LuPlay } from "react-icons/lu";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { addOna, getOnaDetail, OnaEdit } from "../../../services/ona.servise";
import { useTranslation } from "react-i18next";

const OnaCreate = () => {
  const today = new Date();
  const { id } = useParams();
  const isEdit = !!id;
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [repeat, setRepeat] = useState("1");
  const [isInfinity, setIsInfinity] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [allChecked, setAllChecked] = useState(false);
  const [endDate, setEndDate] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [checkedEmployees, setCheckedEmployees] = useState({});
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [popUp, setPopUp] = useState(false);
  const [checkedEmployeesSecond, setCheckedEmployeesSecond] = useState({});
  const [allCheckedSecond, setAllCheckedSecond] = useState(false);
  const [selectedEmployeesSecond, setSelectedEmployeesSecond] = useState([]);
  const [emailText, setEmailText] = useState("");
  const [onaEmployees, setOnaEmployees] = useState([]);

  useEffect(() => {
    getCompanyEmployee()
      .then((data) => {
        setEmployees(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    getDepartments()
      .then((data) => {
        setDepartments(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (isEdit) {
      getOnaDetail(id)
        .then((data) => {
          setData(data.data);
          console.log(data.data);

          if (data.data.endDate === null) {
            setIsInfinity(true);
          } else {
            setIsInfinity(false);
            setEndDate(data.data.endDate);
          }

          // employee selected
          const updatedCheckedEmployees = {};

          data.data.onaSelectedEmployees.forEach((selectedEmployee) => {
            updatedCheckedEmployees[selectedEmployee.employeeId] = true;
          });

          Object.entries(employeesByDepartment).forEach(
            ([departmentName, departmentEmployees]) => {
              const allDepartmentEmployeesChecked = departmentEmployees.every(
                (employee) => updatedCheckedEmployees[employee.id]
              );
              if (allDepartmentEmployeesChecked) {
                updatedCheckedEmployees[departmentName] = true;
              }
            }
          );

          const allEmployeesChecked =
            data.data.onaSelectedEmployees.length === employees.length;

          setCheckedEmployees(updatedCheckedEmployees);
          setAllChecked(allEmployeesChecked);

          const selectedEmployees = employees.filter(
            (employee) => updatedCheckedEmployees[employee.id]
          );
          setSelectedEmployees(selectedEmployees);

          // invited employee
          const filteredOnaEmployees = data.data.onaEmployees.filter(
            (employee) => employee.emailSend === true
          );

          setOnaEmployees(filteredOnaEmployees);

          const updatedCheckedEmployeesSecond = {};

          // Mark the filtered employees as checked
          filteredOnaEmployees.forEach((employee) => {
            updatedCheckedEmployeesSecond[employee.employeeId] = true;
          });

          Object.entries(employeesByDepartment).forEach(
            ([departmentName, departmentEmployees]) => {
              const allDepartmentEmployeesChecked = departmentEmployees.every(
                (employee) => updatedCheckedEmployeesSecond[employee.id]
              );
              if (allDepartmentEmployeesChecked) {
                updatedCheckedEmployeesSecond[departmentName] = true;
              }
            }
          );

          const allEmployeesCheckedSecond =
            filteredOnaEmployees.length === employees.length;

          setCheckedEmployeesSecond(updatedCheckedEmployeesSecond);
          setAllCheckedSecond(allEmployeesCheckedSecond);

          const selectedEmployeesSecond = employees.filter(
            (employee) => updatedCheckedEmployeesSecond[employee.id]
          );
          setSelectedEmployeesSecond(selectedEmployeesSecond);
        })
        .catch((err) => console.log(err));
    }
  }, [isEdit, id]);

  useEffect(() => {
    if (!isEdit) {
      setTitle(titleDate());
    }
  }, []);

  //employee1

  const getDepartmentName = (departmentId) => {
    const department = departments.find((dept) => dept.id === departmentId);
    return department ? department.name : "Unknown Department";
  };

  const employeesByDepartment = employees.reduce((dep, employee) => {
    const departmentId = employee.departmentId;
    const departmentName = getDepartmentName(departmentId);

    if (!dep[departmentName]) {
      dep[departmentName] = [];
    }

    dep[departmentName].push(employee);
    return dep;
  }, {});

  const handleCheckAll = () => {
    const updatedCheckedEmployees = {};
    const newAllChecked = !allChecked;

    employees.forEach((employee) => {
      updatedCheckedEmployees[employee.id] = newAllChecked;
    });

    Object.keys(employeesByDepartment).forEach((departmentName) => {
      updatedCheckedEmployees[departmentName] = newAllChecked;
    });

    setCheckedEmployees(updatedCheckedEmployees);
    setAllChecked(newAllChecked);

    if (newAllChecked) {
      setSelectedEmployees([...employees]);
    } else {
      setSelectedEmployees([]);
    }
  };

  const handleDepartmentCheck = (departmentName) => {
    const updatedCheckedEmployees = { ...checkedEmployees };
    const departmentChecked = updatedCheckedEmployees[departmentName];

    if (departmentChecked) {
      employeesByDepartment[departmentName].forEach((employee) => {
        updatedCheckedEmployees[employee.id] = false;
      });
    } else {
      employeesByDepartment[departmentName].forEach((employee) => {
        updatedCheckedEmployees[employee.id] = true;
      });
    }

    updatedCheckedEmployees[departmentName] = !departmentChecked;
    setCheckedEmployees(updatedCheckedEmployees);

    const selectedEmployees = employees.filter(
      (employee) => updatedCheckedEmployees[employee.id]
    );
    setSelectedEmployees(selectedEmployees);
    setAllChecked(selectedEmployees.length === employees.length);
  };

  const handleEmployeeCheck = (employeeId) => {
    const updatedCheckedEmployees = { ...checkedEmployees };
    updatedCheckedEmployees[employeeId] = !checkedEmployees[employeeId];
    setCheckedEmployees(updatedCheckedEmployees);

    const selectedEmployees = employees.filter(
      (employee) => updatedCheckedEmployees[employee.id]
    );
    setSelectedEmployees(selectedEmployees);
    setAllChecked(selectedEmployees.length === employees.length);
  };

  //employee2

  const handleCheckAllSecond = () => {
    const updatedCheckedEmployees = {};
    const newAllChecked = !allCheckedSecond;

    employees.forEach((employee) => {
      updatedCheckedEmployees[employee.id] = newAllChecked;
    });

    Object.keys(employeesByDepartment).forEach((departmentName) => {
      updatedCheckedEmployees[departmentName] = newAllChecked;
    });

    setCheckedEmployeesSecond(updatedCheckedEmployees);
    setAllCheckedSecond(newAllChecked);

    if (newAllChecked) {
      setSelectedEmployeesSecond([...employees]);
    } else {
      setSelectedEmployeesSecond([]);
    }
  };

  const handleDepartmentCheckSecond = (departmentName) => {
    const updatedCheckedEmployees = { ...checkedEmployeesSecond };
    const departmentChecked = updatedCheckedEmployees[departmentName];

    if (departmentChecked) {
      employeesByDepartment[departmentName].forEach((employee) => {
        updatedCheckedEmployees[employee.id] = false;
      });
    } else {
      employeesByDepartment[departmentName].forEach((employee) => {
        updatedCheckedEmployees[employee.id] = true;
      });
    }

    updatedCheckedEmployees[departmentName] = !departmentChecked;
    setCheckedEmployeesSecond(updatedCheckedEmployees);

    const selectedEmployees = employees.filter(
      (employee) => updatedCheckedEmployees[employee.id]
    );
    setSelectedEmployeesSecond(selectedEmployees);
    setAllCheckedSecond(selectedEmployees.length === employees.length);
  };

  const handleEmployeeCheckSecond = (employeeId) => {
    const updatedCheckedEmployees = { ...checkedEmployeesSecond };
    updatedCheckedEmployees[employeeId] = !checkedEmployeesSecond[employeeId];
    setCheckedEmployeesSecond(updatedCheckedEmployees);

    const selectedEmployees = employees.filter(
      (employee) => updatedCheckedEmployees[employee.id]
    );
    setSelectedEmployeesSecond(selectedEmployees);
    setAllCheckedSecond(selectedEmployees.length === employees.length);
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    if (i18n.language === "en") {
      return `${months[month]} ${day}, ${year}`;
    } else {
      return `${month} сарын ${day}, ${year}`;
    }
  };

  const handleRepeatChange = (event) => {
    setRepeat(Number(event.target.value));
  };

  const handleDateChange = (event) => {
    const selectedDate = new Date(event.target.value);
    const now = new Date();

    if (selectedDate <= now) {
      setErrorMessage(t("ona.create.selectFutureTime"));
      setEndDate("");
    } else {
      const formattedDate = formatDateTime(selectedDate);
      setEndDate(formattedDate);
      setErrorMessage("");
    }
  };

  const formatDateTime = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const now = new Date();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const titleDate = () => {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const createOna = async () => {
    setLoading(true);
    try {
      if (title === "") {
        toast.error(t("Survey.giveNameOnSurvey"));
        setLoading(false);
        return;
      }

      if (repeat === null) {
        toast.error(t("Survey.giveFrequence"));
        setLoading(false);
        return;
      }

      if (endDate === "") {
        toast.error(t("Survey.giveEndDate"));
        setLoading(false);
        return;
      }

      if (
        selectedEmployees.length === 0 ||
        selectedEmployeesSecond.length === 0
      ) {
        toast.error(t("Survey.checkEmployee"));
        setLoading(false);
        return;
      }

      const time = getCurrentDateTime();

      const employeeData = selectedEmployees.map((employee) => ({
        employeeId: employee.id,
      }));
      const secondEmployeeData = selectedEmployeesSecond.map((employee) => ({
        employeeId: employee.id,
        email: employee.email,
      }));

      const ona = {
        name: title,
        onaEmployees: secondEmployeeData,
        onaSelectedEmployees: employeeData,
        interval: repeat,
        startDate: time,
        endDate: endDate,
        language: i18n.language === "en" ? 1 : 0,
      };

      await addOna(ona);
      console.log("success", ona);
      setLoading(false);
      setPopUp(true);
    } catch (error) {
      console.error("Error create the ona: ", error);
      toast.error(t("toast.Error"));
      setLoading(false);
    }
  };

  const editedOna = async () => {
    setLoading(true);

    try {
      const secondEmployeeData = selectedEmployeesSecond
        .filter(
          (employee) =>
            !onaEmployees.some(
              (onaEmployee) => onaEmployee.employeeId === employee.id
            )
        )
        .map((employee) => ({
          employeeId: employee.id,
          email: employee.email,
        }));

      const editedOna = {
        onaId: id,
        onaEmployees: secondEmployeeData,
      };

      await OnaEdit(editedOna);
      // console.log(editedOna);
      setLoading(false);
      setPopUp(true);
    } catch (error) {
      toast.error(t("toast.Error"));
      setLoading(false);
    }
  };

  if (employees.length === 0 || departments.length === 0) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  if (isEdit && data === null) {
    return <Loading />;
  }

  const renderStatus = (status) => {
    switch (status) {
      case "completed":
        return (
          <p className="text-sm text-[#33c915]">{t("Assessment.completed")}</p>
        );
      case "started":
        return (
          <p className="text-sm text-[#1c55ff]">{t("Assessment.started")}</p>
        );
      case "invited":
        return (
          <p className="text-sm text-[#8e8e8e]">
            {t("Assessment.sendInvitation")}
          </p>
        );
      default:
        return "";
    }
  };

  return (
    <div className="bg-[#F3F4F8] min-h-screen">
      <div className="w-8/12 pt-11 m-auto">
        <div className="bg-white px-4 py-6 rounded-xl">
          <p className="text-[#222]">{t("ona.create.title")}</p>
          <div className="px-20">
            <input
              className="w-full text-sm border-[#D4D4D4] rounded-lg mt-3"
              placeholder="Энд бичнэ үү."
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              value={isEdit ? data.name : title}
              type="text"
            />
          </div>
        </div>
        <div className="mt-6">
          <p className="text-[#222]">{t("ona.create.secondTitle")}</p>
          <div className="bg-white px-4 py-6 rounded-xl">
            <div className="px-10">
              <div className="flex items-center justify-between mb-2">
                <p className="font-semibold text-sm text-[#1F2937]">
                  {t("ona.create.dateOfOna")}
                </p>
                <p className="text-xs text-[#1F2937]">
                  {isEdit ? data.startDate : formatDate(today)}
                </p>
              </div>
              <div className="flex items-center justify-between mb-2">
                <p className="font-semibold text-sm text-[#1F2937]">
                  {t("ona.create.frequency")}
                </p>
                <select
                  className="border-none rounded-lg text-xs mt-3 text-[#1F2329]"
                  onChange={handleRepeatChange}
                  value={isEdit ? data.interval : repeat}
                  disabled={isEdit}
                >
                  <option selected value="1">
                    {t("ona.create.oneTimeFrequency")}
                  </option>
                  <option value="14">
                    {t("ona.create.weekTimeFrequency")}
                  </option>
                  <option value="30">
                    {t("ona.create.monthTimeFrequency")}
                  </option>
                  <option value="15">
                    {t("ona.create.seasonsTimeFrequency")}
                  </option>
                  <option value="">
                    {t("ona.create.halfYearTimeFrequency")}{" "}
                  </option>
                  <option value="365">
                    {t("ona.create.yearTimeFrequency")}
                  </option>
                </select>
              </div>
              <div className="mb-2">
                <p className="text-[#1F2329] text-sm font-semibold">
                  {t("ona.create.frequencyEnd")}
                </p>
                <input
                  type="date"
                  className={`w-full rounded-xl border-[#D7D7D7] mt-3 transition-all duration-700 transform ${
                    isInfinity ? "opacity-0 scale-95" : "opacity-100 scale-100"
                  } ${isInfinity ? "hidden" : "block"}`}
                  onChange={handleDateChange}
                  checked={isInfinity}
                />
                {errorMessage && (
                  <p className="text-sm" style={{ color: "red" }}>
                    {errorMessage}
                  </p>
                )}
              </div>

              <div className="flex items-center gap-2 mt-3 ms-3">
                <input
                  className="bg-[#EBEBEB] border-[#BCBCBC] rounded"
                  type="checkbox"
                  value={isInfinity}
                  disabled={isEdit}
                  checked={isInfinity}
                  onChange={() => {
                    setIsInfinity(!isInfinity);
                    setEndDate(null);
                  }}
                />
                <p className="text-xs text-[#1e1e1e]">
                  {t("ona.create.infinity")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-start gap-10 justicy-between">
          <div className="mt-6 w-[50%]">
            <p className="text-[#222]">{t("ona.create.onaMap")}</p>
            <div className="bg-white rounded-xl px-10 py-3">
              <div className="flex justify-end gap-2 items-center my-3">
                <input
                  className="bg-[#EBEBEB]  border-[#BCBCBC] rounded"
                  type="checkbox"
                  onClick={handleCheckAll}
                  checked={allChecked}
                  disabled={isEdit}
                />
                <p className="text-[#1E1E1E] text-xs">
                  {t("ona.create.selectAll")}
                </p>
              </div>
              <div className=" h-[500px] overflow-y-auto">
                {Object.entries(employeesByDepartment).map(
                  ([departmentName, departmentEmployees]) => (
                    <div className="mb-4" key={departmentName}>
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          disabled={isEdit}
                          onChange={() => handleDepartmentCheck(departmentName)}
                          checked={checkedEmployees[departmentName] || false}
                          className="bg-[#EBEBEB]  border-[#BCBCBC] rounded"
                        />
                        <p className="text-sm font-semibold text-[#1E1E1E] ms-2 ">
                          {departmentName}
                        </p>
                      </div>
                      {departmentEmployees.map((employee) => (
                        <div
                          className="ms-4 flex items-center mt-3"
                          key={employee.id}
                        >
                          <div>
                            <input
                              type="checkbox"
                              disabled={isEdit}
                              onChange={() => handleEmployeeCheck(employee.id)}
                              checked={checkedEmployees[employee.id] || false}
                              className="bg-[#EBEBEB]  border-[#BCBCBC] rounded"
                            />
                            <label className="ms-2 text-sm text-[#1E1E1E]">
                              {employee.lastName}, {employee.firstName}
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
          <div className="mt-6 w-[50%]">
            <p className="text-[#222]">{t("ona.create.onaInvite")}</p>
            <div className="bg-white rounded-xl px-10 py-3">
              <div className="flex justify-end gap-2 items-center my-3">
                <input
                  className="bg-[#EBEBEB]  border-[#BCBCBC] rounded"
                  type="checkbox"
                  onClick={handleCheckAllSecond}
                  checked={allCheckedSecond}
                />
                <p className="text-[#1E1E1E] text-xs">
                  {t("ona.create.selectAll")}
                </p>
              </div>
              <div className=" h-[500px] overflow-y-auto">
                {Object.entries(employeesByDepartment).map(
                  ([departmentName, departmentEmployees]) => (
                    <div className="mb-4" key={departmentName}>
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          onChange={() =>
                            handleDepartmentCheckSecond(departmentName)
                          }
                          checked={
                            checkedEmployeesSecond[departmentName] || false
                          }
                          className="bg-[#EBEBEB]  border-[#BCBCBC] rounded"
                        />
                        <p className="text-sm font-semibold text-[#1E1E1E] ms-2 ">
                          {departmentName}
                        </p>
                      </div>
                      {departmentEmployees.map((employee) => {
                        const onaEmployee = onaEmployees.find(
                          (onaEmployee) =>
                            onaEmployee.employeeId === employee.id
                        );
                        const isEmployeeInOnaEmployees = !!onaEmployee;
                        const completeType = onaEmployee?.completeType;

                        return (
                          <div
                            className="ms-4 flex items-center mt-3"
                            key={employee.id}
                          >
                            <div>
                              <input
                                type="checkbox"
                                onChange={() =>
                                  handleEmployeeCheckSecond(employee.id)
                                }
                                checked={
                                  checkedEmployeesSecond[employee.id] || false
                                }
                                disabled={isEmployeeInOnaEmployees}
                                className="bg-[#EBEBEB] border-[#BCBCBC] rounded"
                              />
                              <label className="ms-2 text-sm text-[#1E1E1E]">
                                {employee.lastName}, {employee.firstName}{" "}
                                {isEdit ? renderStatus(completeType) : ""}
                              </label>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end pb-10 gap-7 mt-8">
          {/* <div className="flex flex-col items-center">
            <button
              className={` rounded-xl bg-[#E4FFEB] text-center py-3 px-4 hover:mb-2`}
              disabled={loading}
            >
              {loading ? (
                <span className="spinner animate-spin">
                  <FaSpinner />
                </span>
              ) : (
                <span>
                  <FaRegBookmark className="text-[#00962A] h-[18px] w-[14px]" />
                </span>
              )}
            </button>
            <p className="text-sm text-[#00962A] text-semibold text-center mt-1">
              {loading ? "Уншиж байна" : "Илгээх"}
            </p>
          </div>
          <div className="flex flex-col items-center">
            <button
              className={` rounded-xl bg-[#FFEFE3] text-center py-3 px-4 hover:mb-2`}
              disabled={loading}
            >
              {loading ? (
                <span className="spinner animate-spin">
                  <FaSpinner />
                </span>
              ) : (
                <span>
                  <LuPlay className="text-[#FF6B00] h-[18px] " />
                </span>
              )}
            </button>
            <p className="text-sm text-[#FF6B00] text-semibold text-center mt-1">
              {loading ? "Уншиж байна" : "Туршиж үзэх"}
            </p>
          </div> */}
          {console.log("onaEmployees", onaEmployees)}
          {console.log("selected", selectedEmployeesSecond)}
          <div className="flex flex-col items-center">
            <button
              className={`rounded-xl bg-[#E3EBFF] text-center py-3 px-4 hover:mb-2`}
              disabled={loading}
              onClick={isEdit ? editedOna : createOna}
            >
              {loading ? (
                <span className="spinner">
                  <FaSpinner className="spin-animation" />
                </span>
              ) : (
                <span>
                  <svg
                    width="16"
                    height="20"
                    viewBox="0 0 16 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 5.77L12.28 16.2L8.81 14.67L8 14.31L7.19 14.67L3.72 16.2L8 5.77ZM8 0.5L0.5 18.79L1.21 19.5L8 16.5L14.79 19.5L15.5 18.79L8 0.5Z"
                      fill="#084DFF"
                    />
                  </svg>
                </span>
              )}
            </button>
            <p className="text-sm text-[#084DFF] text-semibold text-center mt-1">
              {loading ? t("ona.create.loading") : t("ona.create.sent")}
            </p>
          </div>
        </div>
      </div>
      <ToastContainer />
      {popUp && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-xl p-6 w-[548px] h-[329px] flex flex-col justify-center">
            <div className="flex items-center justify-center">
              <img src="/survey/surveyPop.png" alt="pop" />
            </div>
            <div className="text-center mt-4">
              <p className="text-[24px] font-bold text-[#444]">
                {t("ona.create.addedSuccessfully")}
              </p>
              <p className="text-[#616161] text-[20px] mt-2">
                {t("ona.create.addedSuccessfullyDesc")}
              </p>
              <button
                onClick={() => {
                  navigate("/company/ona");
                }}
                className="mt-4 px-8 py-2 bg-[#222] text-lg rounded-xl text-white"
              >
                {t("ona.create.finish")}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OnaCreate;
