import axios from 'axios';

const askQuestionApi = (question) => {
    return axios.get('https://app-metacog-chat-assisstant.azurewebsites.net/api/funcchatassisstant', {
        params: {
            code: 'vPhA0wBlYzSXQlM5MEXcgdJZMoPWpydCz9fLZVdhhTiJAzFu-IdbBA==',
            question: question
        }
    })
        .then(response => {
            return response
            // Handle the response here
        })
        .catch(error => {
            console.error('Error:', error);
            // Handle errors here
        });

}


export { askQuestionApi }