import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useUserContext } from "./context/UserContext"; // Import the UserContextProvider

import { Box, Modal } from "@mui/material";
import {
  BrowserRouter,
  Link,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import MainButton from "./components/buttons/MainButton";
import Admin from "./containers/admin/Admin";
import Assessment from "./containers/assessment/Assessment";
import AssessmentDemo from "./containers/assessment/AssessmentDemo";
import AssessmentPlayer from "./containers/assessment/AssessmentPlayer";
import AssessmentPlayerDemo from "./containers/assessment/AssessmentPlayerDemo";
import AssessmentReport from "./containers/assessment/AssessmentReport";
import AssessmentReportOpen from "./containers/assessment/AssessmentReportOpen";
import AssessmentTotalPlayers from "./containers/assessment/AssessmentTotalPlayers";
import NewAssessment from "./containers/assessment/NewAssessment";
import CV from "./containers/assessment/PlayerProfile/CV";
import GameData from "./containers/assessment/PlayerProfile/GameData";
import NewGameData from "./containers/assessment/PlayerProfile/NewGameData";
import Assisstant from "./containers/assisstant/Assisstant";
import AddWorker from "./containers/company/AddWorker";
import BurnOut from "./containers/company/BurnOut";
import NewSurvey from "./containers/company/NewSurvey";
import Ona from "./containers/company/Ona";
import Survey from "./containers/company/Survey";
import Workers from "./containers/company/Workers";
import BurnOutDepDemo from "./containers/company/burnout/BurnOutDepDemo";
import BurnOutReport from "./containers/company/burnout/BurnOutReport";
import BurnOutReportDemo from "./containers/company/burnout/BurnOutReportDemo";
import BurnoutReportTable from "./containers/company/burnout/BurnOutReportTable";
import BurnOutTableDemo from "./containers/company/burnout/BurnOutTableDemo";
import ContractEmployeeTable from "./containers/company/burnout/ContractEmployeeTable";
import MainEmployeeTable from "./containers/company/burnout/MainEmployeeTable";
import Permission from "./containers/company/burnout/questions/Permission";
import PregameQuestion from "./containers/company/burnout/questions/PregameQuestion";
import DepartmentDetail from "./containers/company/departmentOrgChart/DepartmentDetail";
import OrgChartComponent from "./containers/company/departmentOrgChart/OrgChartComponent";
import OnaCreate from "./containers/company/ona/OnaCreate";
import OnaReport from "./containers/company/ona/OnaReport";
import ComingSoonResearch from "./containers/company/surveySections/ComingSoonResearch";
import CreateSurvey from "./containers/company/surveySections/CreateSurvey";
import EditSurvey from "./containers/company/surveySections/EditSurvey";
import QuestionsPage from "./containers/company/surveySections/QuestionsPage";
import SurveyNewReport from "./containers/company/surveySections/SurveyNewReport";
import SurveyQuestionsAdd from "./containers/company/surveySections/SurveyQuestionsAdd";
import SurveySummary from "./containers/company/surveySections/SurveySummary";
import SurveyTable from "./containers/company/surveySections/SurveyTable";
import ContractWorkersTable from "./containers/company/workersTable/ContractWorkersTable";
import EmployeeDetail from "./containers/company/workersTable/EmployeeDetail/EmployeeDetail";
import EmployeePersonalDetail from "./containers/company/workersTable/EmployeeDetail/EmployeePersonalDetail";
import WorkerInfo from "./containers/company/workersTable/EmployeeDetail/WorkerInfo";
import MainWorkersTable from "./containers/company/workersTable/MainWorkersTable";
import NewWorkersTable from "./containers/company/workersTable/NewWorkersTable";
import ApuFlow from "./containers/game/ApuFlow";
import BurnoutFlow from "./containers/game/BurnoutFlow";
import EmojiGame from "./containers/game/EmojiGame";
import GameFlow from "./containers/game/GameFlow";
import PulseSurveyGame from "./containers/game/PulseSurveyGame";
import BurnoutGame from "./containers/games/Burnout";
import Metacog from "./containers/games/Metacog";
import MobileGame from "./containers/games/MobileGame";
import Home from "./containers/home/Home";
import Refer from "./containers/promotion/Refer";
import MyProfile from "./containers/settings/MyProfile";
import MySettings from "./containers/settings/MySettings";
import AccountForm from "./containers/test/accountForm";
import StudentForm from "./containers/test/studentForm";
import TestForm from "./containers/test/testForm";
import { AuthProvider, useAuth } from "./context/AuthContext";
import BurnoutQrRegister from "./pages/BurnoutQrRegister";
import ChangeUrl from "./pages/ChangeUrl";
import ComingSoon from "./pages/ComingSoon";
import CompanyConfirmationEmail from "./pages/CompanyConfirmationEmail";
import RegistrationSuccess from "./pages/CompanySuccessful";
import ControlDashboard from "./pages/ControlDashboard";
import Dashboard from "./pages/Dashboard";
import HandBook from "./pages/Handbook";
import NotStarted from "./pages/NotStarted";
import NotFound from "./pages/Notfound";
import Subscription from "./pages/Subscription";
import TotalRequest from "./pages/TotalRequest";
import UsePC from "./pages/UsePC";
import Congratz from "./pages/congratz";
import EmployeeDashboard from "./pages/dashboard/EmployeeDashboard";
import SkillDashboard from "./pages/dashboard/SkillDashboard";
import UserInformation from "./pages/profileDetail/UserInformation";
import UserSettings from "./pages/profileDetail/UserSettings";
import Contract from "./pages/subscription/Contract";
import Done from "./pages/subscription/Done";
import Payment from "./pages/subscription/Payment";
import Header from "./partials/Header";
import Sidebar from "./partials/Sidebar";
import Login from "./partials/login";
import CompanySign from "./partials/signup /CompanySign";
import SignUp from "./partials/signup /SignUp";
import TermsOfService from "./partials/signup /TermsOfService";
import { getAccountInfo } from "./services/user.service";
import Analystic from "./containers/company/Analystic";
import Feedback from "./containers/company/Feedback";
import OnBoarding from "./containers/company/OnBoarding";
import OnBoardingCreate from "./containers/company/onBoarding/OnBoardingCreate";
import OnBoardingReport from "./containers/company/onBoarding/OnBoardingReport";
import SoftSkill from "./containers/company/SoftSkill";
import SoftSkillReport from "./containers/company/softSkill/SoftSkillReport";
import SoftSkillResearch from "./containers/company/softSkill/SoftSkillResearch";
import SoftSkillCreate from "./containers/company/softSkill/SoftSkillCreate";
import SoftSkillDep from "./containers/company/softSkill/SoftSkillDep";
import BurnOutCreate from "./containers/company/burnout/BurnOutCreate";
import AnalysticDemo from "./containers/company/AnalyticsDemo";
import SendedFeedback from "./containers/company/feedback/SendedFeedback";
import OnboardFeedback from "./containers/company/feedback/OnboardFeedback";
import FeedBackComingSoon from "./containers/company/feedback/FeedBackComingSoon";

const ProtectedRoute = ({ children }) => {
  const token = Cookies.get("access_token");
  const { user, hasRole } = useAuth();

  if (!token) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "white",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  borderRadius: "25px",
};

const Layout = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith("/company/feedback")) {
      setSidebarOpen(false);
    } else {
      setSidebarOpen(true);
    }
  }, [location]);

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main>
          <Outlet />
        </main>
      </div>
    </div>
  );
};

function App() {
  const [trialEnd, setTrialEnd] = useState(false);
  const { user, setUser } = useUserContext(); // Use the hook directly within the component

  useEffect(() => {
    const accessToken = Cookies.get("access_token");
    if (accessToken === null || accessToken === undefined) return;
    getAccountInfo().then((val) => {
      const { data } = val;

      setUser(data);

      if (data?.companyStatus === "INACTIVE") {
        setTrialEnd(true);
      }
    });
  }, []);

  return (
    <AuthProvider>
      <BrowserRouter>
        <Modal
          open={
            user !== null && user?.companyStatus === "INACTIVE" ? true : false
          }
        >
          <Box sx={style}>
            <div className="flex justify-center items-center">
              <div className="text-center">
                {/* <CircularProgress className="ml-11" /> */}
                <div className="flex justify-center pb-4 pt-7">
                  <img src="/icons/circle-alert.png" />
                </div>
                <p className="font-bold pb-4">
                  It looks like your account is expired
                </p>
                <p>
                  Please contact us at hello@metacog.mn or set up a meeting
                  below
                </p>

                <div className="pb-7 pt-4 flex justify-center">
                  <div className="w-[300px]">
                    <a
                      href="https://calendly.com/metacog-metrics/metacog-demo?month=2023-12"
                      target="_blank"
                    >
                      <MainButton
                        label={"Set up a meeting with us"}
                        color={"bg-main-blue"}
                        labelColor={"text-white"}
                      />
                    </a>
                  </div>
                  <div className="ml-3">
                    <Link to="/login">
                      <MainButton
                        label={"Sign out"}
                        color={"bg-rose-500"}
                        labelColor={"text-white"}
                        onClick={() => {
                          Cookies.remove("access_token");
                          setUser(null);
                        }}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
        <Routes>
          <Route element={<Layout />}>
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />
            <Route
              path="/assisstant"
              element={
                <ProtectedRoute>
                  <Assisstant />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin"
              element={
                <ProtectedRoute>
                  <Admin />
                </ProtectedRoute>
              }
            />
            <Route
              path="/customer/assessments/new"
              element={
                <ProtectedRoute>
                  <NewAssessment />
                </ProtectedRoute>
              }
            />
            <Route
              path="/company/ona"
              element={
                <ProtectedRoute>
                  <Ona />{" "}
                </ProtectedRoute>
              }
            />
            <Route
              path="/company/ona/report/:id"
              element={
                <ProtectedRoute>
                  <OnaReport />{" "}
                </ProtectedRoute>
              }
            />
            <Route
              path="/company/ona/create"
              element={
                <ProtectedRoute>
                  <OnaCreate />
                </ProtectedRoute>
              }
            />
            <Route
              path="/company/ona/edit/:id"
              element={
                <ProtectedRoute>
                  <OnaCreate />
                </ProtectedRoute>
              }
            />
            <Route
              path="/subscription"
              element={
                <ProtectedRoute>
                  <Subscription />
                </ProtectedRoute>
              }
            />
            <Route
              path="/subscription/payment"
              element={
                <ProtectedRoute>
                  <Payment />
                </ProtectedRoute>
              }
            />
            <Route
              path="/subscription/done"
              element={
                <ProtectedRoute>
                  <Done />
                </ProtectedRoute>
              }
            />
            <Route
              path="/customer/assessments/edit/:id"
              element={
                <ProtectedRoute>
                  <NewAssessment />
                </ProtectedRoute>
              }
            />
            <Route
              path="/customer/assessments/:id"
              element={
                <ProtectedRoute>
                  <Assessment />
                </ProtectedRoute>
              }
            />
            <Route
              path="/customer/assessments/total"
              element={
                <ProtectedRoute>
                  <AssessmentTotalPlayers />
                </ProtectedRoute>
              }
            />
            <Route
              path="/customer/assessments/demo"
              element={
                <ProtectedRoute>
                  <AssessmentDemo />
                </ProtectedRoute>
              }
            />
            <Route
              path="3"
              element={
                <ProtectedRoute>
                  <AssessmentReport />
                </ProtectedRoute>
              }
            />
            <Route
              path="/demo/player/:id"
              element={
                <ProtectedRoute>
                  <AssessmentPlayerDemo />
                </ProtectedRoute>
              }
            />
            <Route
              path="/player/:id"
              element={
                <ProtectedRoute>
                  <AssessmentPlayer />
                </ProtectedRoute>
              }
            >
              <Route index element={<AssessmentPlayer />} />
              <Route path="cv" element={<CV />} />
              <Route path="gameData" element={<GameData />} />
              <Route path="newGameData" element={<NewGameData />} />
            </Route>
            <Route
              path="/coming_soon"
              element={
                <ProtectedRoute>
                  <ComingSoon />
                </ProtectedRoute>
              }
            />
            <Route
              path="/company/analytic"
              element={
                <ProtectedRoute>
                  <Analystic />
                </ProtectedRoute>
              }
            />
            <Route
              path="/company/analytic/demo"
              element={
                <ProtectedRoute>
                  <AnalysticDemo />
                </ProtectedRoute>
              }
            />
            <Route
              path="/company/comingSoon"
              element={
                <ProtectedRoute>
                  <FeedBackComingSoon />
                </ProtectedRoute>
              }
            />
            <Route
              path="/company/feedback"
              element={
                <ProtectedRoute>
                  <Feedback />
                </ProtectedRoute>
              }
            >
              <Route index element={<Feedback />} />
              <Route path="report" element={<SendedFeedback />} />
              <Route path="onboard" element={<OnboardFeedback />} />
            </Route>
            <Route
              path="/company/onBoarding"
              element={
                <ProtectedRoute>
                  <OnBoarding />
                </ProtectedRoute>
              }
            />
            <Route
              path="/company/onBoarding/create"
              element={
                <ProtectedRoute>
                  <OnBoardingCreate />
                </ProtectedRoute>
              }
            />
            <Route
              path="/company/onBoarding/report"
              element={
                <ProtectedRoute>
                  <OnBoardingReport />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings/profile"
              element={
                <ProtectedRoute>
                  <MyProfile />
                </ProtectedRoute>
              }
            >
              <Route inxed element={<MyProfile />} />
              <Route path="information" element={<UserInformation />} />
              <Route path="settings" element={<UserSettings />} />
            </Route>
            <Route
              path="/settings/settings"
              element={
                <ProtectedRoute>
                  <MySettings />
                </ProtectedRoute>
              }
            />
            <Route
              path="/company/survey"
              element={
                <ProtectedRoute>
                  <Survey />
                </ProtectedRoute>
              }
            />
            <Route
              path="/company/newSurvey"
              element={
                <ProtectedRoute>
                  <NewSurvey />
                </ProtectedRoute>
              }
            />
            <Route
              path="/company/softSkill"
              element={
                <ProtectedRoute>
                  <SoftSkill />
                </ProtectedRoute>
              }
            >
              <Route index element={<SoftSkill />} />
              <Route path="report" element={<SoftSkillReport />} />
              <Route path="research" element={<SoftSkillResearch />} />
            </Route>
            <Route
              path="/company/softSkill/dep"
              element={
                <ProtectedRoute>
                  <SoftSkillDep />
                </ProtectedRoute>
              }
            />
            <Route
              path="/company/softSkill/create"
              element={
                <ProtectedRoute>
                  <SoftSkillCreate />
                </ProtectedRoute>
              }
            />
            <Route
              path="/company/create/survey"
              element={
                <ProtectedRoute>
                  <CreateSurvey />
                </ProtectedRoute>
              }
            />
            <Route
              path="/company/edit/survey/:id"
              element={
                <ProtectedRoute>
                  <CreateSurvey />
                </ProtectedRoute>
              }
            />
            <Route
              path="/totalRequest"
              element={
                <ProtectedRoute>
                  <TotalRequest />
                </ProtectedRoute>
              }
            />
            <Route
              path="/refer"
              element={
                <ProtectedRoute>
                  <Refer />
                </ProtectedRoute>
              }
            />
            <Route
              path="/company/survey/report/:surveyId/questions/:questionId"
              element={
                <ProtectedRoute>
                  <QuestionsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/company/burnout/report/:id"
              element={
                <ProtectedRoute>
                  <BurnOutReport />
                </ProtectedRoute>
              }
            />{" "}
            <Route
              path="/company/burnout/report/demo/:id"
              element={
                <ProtectedRoute>
                  <BurnOutReportDemo />
                </ProtectedRoute>
              }
            />
            <Route
              path="/company/burnout/demo"
              element={
                <ProtectedRoute>
                  <BurnOutTableDemo />
                </ProtectedRoute>
              }
            />{" "}
            <Route
              path="/company/burnout/create"
              element={
                <ProtectedRoute>
                  <BurnOutCreate />
                </ProtectedRoute>
              }
            />{" "}
            <Route
              path="/company/burnout/demo/dep"
              element={
                <ProtectedRoute>
                  <BurnOutDepDemo />
                </ProtectedRoute>
              }
            />
            <Route
              path="/company/burnout"
              element={
                <ProtectedRoute>
                  <BurnOut />
                </ProtectedRoute>
              }
            >
              <Route index element={<BurnOut />} />
              <Route path="main" element={<MainEmployeeTable />} />
              <Route path="contract" element={<ContractEmployeeTable />} />
              <Route path="report" element={<BurnoutReportTable />} />
            </Route>
            <Route
              path="/company/survey/coming_soon"
              element={
                <ProtectedRoute>
                  <ComingSoonResearch />
                </ProtectedRoute>
              }
            />
            {/* <Route
              path="/company/survey/report/:id"
              element={
                <ProtectedRoute>
                  <SurveyReport />
                </ProtectedRoute>
              }
            /> */}
            <Route
              path="/company/survey/report/:id"
              element={
                <ProtectedRoute>
                  <SurveyNewReport />
                </ProtectedRoute>
              }
            >
              <Route inxed element={<SurveyNewReport />} />
              <Route path="summary" element={<SurveySummary />} />
              <Route path="table" element={<SurveyTable />} />
              <Route path="edit" element={<EditSurvey />} />
            </Route>
            <Route
              path="/company/survey/questions_add"
              element={
                <ProtectedRoute>
                  <SurveyQuestionsAdd />
                </ProtectedRoute>
              }
            />
            <Route
              path="/company/survey/questions_edit/:id"
              element={
                <ProtectedRoute>
                  <SurveyQuestionsAdd />
                </ProtectedRoute>
              }
            />
            <Route
              path="/control"
              element={
                <ProtectedRoute>
                  <ControlDashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            >
              <Route inxed element={<Dashboard />} />
              <Route path="skill" element={<SkillDashboard />} />
              <Route path="employee" element={<EmployeeDashboard />} />
            </Route>
            <Route exact path="/handbook" element={<HandBook />} />
            {/* 
          <Route
            exact
            path="/company/employee/add "
            element={
                            <ProtectedRoute>
                <AddWorker />
              </ProtectedRoute>
            }
          /> */}
            <Route
              exact
              path="/company/employee/add/:id"
              element={
                <ProtectedRoute>
                  <AddWorker />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/company/employee/add/"
              element={
                <ProtectedRoute>
                  <AddWorker />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/company/employee/employee_info/:id"
              element={
                <ProtectedRoute>
                  <WorkerInfo />
                </ProtectedRoute>
              }
            >
              <Route index element={<WorkerInfo />} />
              <Route path="employee_detail" element={<EmployeeDetail />} />
              <Route
                path="personal_detail"
                element={<EmployeePersonalDetail />}
              />
            </Route>
            <Route
              exact
              path="/company/employee/chart/department_detail"
              element={
                <ProtectedRoute>
                  <DepartmentDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path="/company/employee"
              element={
                <ProtectedRoute>
                  <Workers />
                </ProtectedRoute>
              }
            >
              <Route index element={<Workers />} />
              <Route path="main" element={<MainWorkersTable />} />
              <Route path="new" element={<NewWorkersTable />} />
              <Route path="Contract" element={<ContractWorkersTable />} />
              <Route path="chart" element={<OrgChartComponent />} />
            </Route>
          </Route>
          <Route
            path="/subscription"
            element={
              <ProtectedRoute>
                <Subscription />
              </ProtectedRoute>
            }
          />
          <Route
            path="/subscription/payment"
            element={
              <ProtectedRoute>
                <Payment />
              </ProtectedRoute>
            }
          />
          <Route
            path="/subscription/contract"
            element={
              <ProtectedRoute>
                <Contract />
              </ProtectedRoute>
            }
          />

          <Route exact path="/signup" element={<SignUp />} />
          <Route exact path="/signup/company" element={<CompanySign />} />
          <Route
            exact
            path="/signup/thermofservice"
            element={<TermsOfService />}
          />
          <Route exact path="/login" element={<Login />} />
          <Route
            exact
            path="/player/open-report/:id"
            element={<AssessmentReportOpen />}
          />

          <Route
            exact
            path="/company/burnout/questions"
            element={<PregameQuestion />}
          />
          <Route
            exact
            path="/company/burnout/questions/permission"
            element={<Permission />}
          />
          <Route exact path="/game/:id/metacogtest" element={<Metacog />} />
          <Route exact path="/game/:id/burnout" element={<BurnoutGame />} />
          <Route exact path="/game/rd/:id" element={<MobileGame />} />
          <Route exact path="/:uniqId" element={<TestForm />} />
          <Route exact path="/student/:uniqId" element={<StudentForm />} />
          <Route exact path="/congratz" element={<Congratz />} />
          <Route exact path="/game/:id" element={<GameFlow />} />
          <Route exact path="/apugame/:id" element={<ApuFlow />} />
          <Route exact path="/bo/:textUrl" element={<BurnoutFlow />} />
          <Route exact path="/notfound" element={<NotFound />} />
          <Route exact path="/use-pc" element={<UsePC />} />
          <Route exact path="/not-in-time" element={<NotStarted />} />
          {/* <Route exact path="/account" element={<AccountForm />} /> */}
          <Route exact path="/globalhr_vip" element={<AccountForm />} />
          <Route exact path="/globalhr_active" element={<AccountForm />} />
          <Route exact path="/register/lemonpress" element={<ChangeUrl />} />
          <Route
            exact
            path="/email/comfirmation"
            element={<CompanyConfirmationEmail />}
          />
          <Route
            exact
            path="/company/successful"
            element={<RegistrationSuccess />}
          />
          <Route
            exact
            path="/pulse/survey/zuma/find"
            element={<PulseSurveyGame />}
          />
          <Route
            exact
            path="/pulse/survey/emoji/find"
            element={<EmojiGame />}
          />
          {/* <Route exact path="/pulse/survey" element={<PulseSurveyGame />} /> */}
          <Route
            exact
            path="/game/register/:id"
            element={<BurnoutQrRegister />}
          />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
