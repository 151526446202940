import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { toast, ToastContainer } from "react-toastify";
import { createQrCodePlayer } from "../services/burnout.service";
import { useTranslation } from "react-i18next";

const BurnoutQrRegister = () => {
  const [name, setName] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const onSubmit = () => {
    if (name === "") {
      toast.error(t("toast.enterName"));
    } else {
      createQrCodePlayer({ name: name }).then((val) => {
        setName("");
        toast.success(t("toast.successEnter"));
        window.location = val.data.startUrl;
      });
    }
  };

  return (
    <>
      <div className="min-h-screen flex items-center justify-center relative bg-[#0f0f0f]">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.7 }}
          style={{ display: isVisible ? "block" : "none" }}
        >
          <div>
            <label className="text-white block ms-2 mb-2">
              Нэрээ оруулна уу.
            </label>
            <input
              className="border-b rounded-xl px-2 w-[280px] h-[40px] text-lg border-white bg-inherit focus:border-b text-white "
              placeholder="Таны нэр"
              onChange={(e) => {
                setName(e.target.value);
              }}
              type="text"
            />
          </div>
          <div className="flex justify-center">
            <button
              onClick={onSubmit}
              className={`px-10 py-2 bg-inherit border absolute bottom-10 rounded-xl border-white text-white opacity-80 hover:opacity-100`}
            >
              Үргэлжлүүлэх
            </button>
          </div>
        </motion.div>
        <ToastContainer />
      </div>
    </>
  );
};

export default BurnoutQrRegister;
