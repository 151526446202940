import React, { useEffect, useRef, useState } from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import { Link } from "react-router-dom";
import MainInput from "../../../components/input/MainInput";
import { BiPlus } from "react-icons/bi";
import { AiOutlineMinus } from "react-icons/ai";
import { getCompanyEmployee } from "../../../services/employee.service";
import { useTranslation } from "react-i18next";
import Loading from "../../../components/uncommon/Loading";

const treeStyle = {
  padding: "5px",
  borderRadius: "8px",
  display: "inline-block",
  border: "1px solid red",
};
const containerStyle = {
  overflow: "auto",
  width: "100%",
  height: "80vh",
  display: "flex",
  maxWidth: "100vw",
};

function organizeData(data) {
  const treeData = {};

  data.forEach((item) => {
    const parentId = item.parentId || null;

    if (!treeData[parentId]) {
      treeData[parentId] = [];
    }

    treeData[parentId].push(item);
  });

  function createTree(parentId) {
    const children = treeData[parentId] || [];

    return children.map((child) => ({
      ...child,
      children: createTree(child.id),
    }));
  }

  const hierarchicalData = createTree(null);
  return hierarchicalData || [];
}

const OrgChartComponent = () => {
  const { t } = useTranslation();
  const [zoomLevel, setZoomLevel] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [employee, setEmployee] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const modalRef = useRef(null);

  // employee
  useEffect(() => {
    try {
      getCompanyEmployee()
        .then((data) => {
          setEmployee(data.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching employee data:", error);
        });
    } catch (err) {
      console.error("Error in useEffect:", err);
    }
  }, []);

  //zoom
  const handleZoomIn = () => {
    setZoomLevel((prevZoom) => prevZoom + 0.1);
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(0.1, prevZoom - 0.1));
  };

  //modal
  const modalButton = () => {
    setIsOpen(!isOpen);
  };

  const handleModalClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      modalButton();
    }
  };

  const hierarchicalData = organizeData(employee);
  console.log("hierarachialDta ==", hierarchicalData);

  const renderNode = (node) => (
    // <Link to="/company/employee/chart/department_detail">
    <button
      className="py-2 px-4 flex items-center justify-center"
      style={{
        ...treeStyle,
        border: `1px solid ${node?.id % 2 === 0 ? "red" : "blue"}`,
      }}
      onClick={() => {}}
    >
      {console.log("node", node)}
      <div>
        <div className="flex item-center justify-center">
          <img width={29} height={29} src={node?.profileUrl} alt="user" />
        </div>
        <div>
          <p>
            {node?.firstName} {node?.lastName}
          </p>
          <p className="text-gray-400 text-xs">{node?.position}</p>
        </div>
      </div>
    </button>
    // </Link>
  );

  const renderTreeNode = (data) => (
    <TreeNode key={data?.id} label={renderNode(data)}>
      {data?.children &&
        data?.children.map(
          (child) => (
            console.log("data", data),
            (
              <React.Fragment key={child?.id}>
                {renderTreeNode(child)}
              </React.Fragment>
            )
          )
        )}
    </TreeNode>
  );

  if (isLoading) {
    return (
      <div className="min-h-screen flex justify-center items-center">
        <Loading />
      </div>
    );
  }

  if (employee.length === 0) {
    return (
      <div className="m-auto w-10/12 pt-11 text-2xl text-[#324d72] font-bold">
        {t("OrgChart.noEmployee")}
      </div>
    );
  }

  const numberEmployee = employee.length === 0 ? 0 : employee.length;

  return (
    <>
      <div className="">
        <div className="flex justify-between">
          <div>
            <p className="text-2xl font-bold">{t("OrgChart.title")}</p>
            <p className="text-gray-400 text-base ">
              {numberEmployee} {t("OrgChart.employeeNumber")}
            </p>
          </div>
          {/* <div>
            <button
              onClick={modalButton}
              className="flex items-center text-white bg-[#324D72] hover:bg-[#324D90] py-2 px-3 rounded-lg"
            >
              Хэлтэс нэмэх
              <BiPlus className="text-white w-6 h-6 font-bold ms-2" />
            </button>
          </div> */}
        </div>
        <div className="mt-4">
          <button
            className="px-2 py-2 mx-2 rounded-full border border-3"
            onClick={handleZoomIn}
          >
            <BiPlus className="text-xl" />
          </button>
          <button
            className="px-2 py-2 mx-2 rounded-full border border-3"
            onClick={handleZoomOut}
          >
            <AiOutlineMinus className="text-xl" />
          </button>
        </div>
        <div className="mt-6 p-4 bg-[#F5F5F6]" style={containerStyle}>
          <div
            style={{
              borderTop: "none",
              transformOrigin: "top left",
              transform: `scale(${zoomLevel})`,
            }}
          >
            <Tree
              lineWidth={"2px"}
              lineColor={"#3D608C"}
              lineBorderRadius={"10px"}
            >
              {renderTreeNode(hierarchicalData[0])}
            </Tree>
          </div>
        </div>
      </div>
      {isOpen && (
        <div
          onClick={handleModalClickOutside}
          className="fixed inset-0 flex items-center justify-center z-50"
        >
          <div className="fixed inset-0 bg-black opacity-50 transition-opacity duration-300"></div>
          <div
            className="bg-white w-1/2 rounded-lg p-4 relative transform transition-transform duration-300"
            ref={modalRef}
          >
            <div className="modal-content p-10">
              <p className="text-4xl font-bold mb-5">Хэлтэс</p>
              <div className="mb-4">
                <MainInput
                  placeholder="Хэлтэсийн нэр"
                  className="border mb-3"
                />
                <MainInput placeholder="Чиглэл" className="border mb-3" />
                <select className="border w-full border-gray-300 text-gray-900  rounded-lg  p-2.5">
                  <option value="" disabled selected hidden>
                    Сонгох
                  </option>
                  <option value="department1">Department 1</option>
                  <option value="department2">Department 2</option>
                  <option value="department3">Department 3</option>
                </select>
              </div>
              <div className="mt-10 flex justify-end gap-5">
                <button
                  type="submit"
                  onClick={modalButton}
                  className="py-3 px-6 bg-[#324d76] hover:bg-[#324d90] font-bold  text-white rounded-lg "
                >
                  Нэмэх
                </button>
                <button
                  className="py-3 px-6 rounded-lg border font-bold hover:bg-[#f0f0f0]"
                  onClick={modalButton}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OrgChartComponent;
