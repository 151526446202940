import api from "../helper/request";

const getCompanyEmployee = () => api.get("/employees");
const AddCompanyEmployee = (obj) => api.post("/employees", obj);
const getCompanyNewEmployee = () =>
  api.get("/employees/sort", {
    params: {
      type: "NEW",
    },
  });
const getCompanyOfficialEmployee = () =>
  api.get("/employees/sort", {
    params: {
      type: "OFFICIAL",
    },
  });
const getCompanyContractEmployee = () =>
  api.get("/employees/sort", {
    params: {
      type: "CONTRACT",
    },
  });
const getDepartments = () => api.get("/departments");
const deleteEmployee = (id) => api.delete(`/employees/${id}`);
const getEmployee = (id) => api.get(`/employees/${id}`);
const editEmployee = (obj) => api.post(`employees/edit`, obj);
const getSurveys = () => api.get("/survey");
const getTeams = () => api.get("/team");
const getAlbas = () => api.get("/alba");
const exportCreateEmployees = obj => api.post("/employees/import/excel", obj, {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
})

export {
  getCompanyEmployee,
  AddCompanyEmployee,
  getCompanyNewEmployee,
  getDepartments,
  getCompanyContractEmployee,
  getCompanyOfficialEmployee,
  deleteEmployee,
  getEmployee,
  editEmployee,
  getSurveys,
  getTeams,
  exportCreateEmployees,
  getAlbas
};
