import { useEffect, useState } from "react";
import { BiSearchAlt } from "react-icons/bi";
import { Link } from "react-router-dom";
import MainTable from "../../../components/table/MainTable";
import AgCellMenu from "../../../components/menu/AgCellMenu";
import {
  getCompanyOfficialEmployee,
  deleteEmployee,
} from "../../../services/employee.service";
import { BsFillTrashFill } from "react-icons/bs";
import { GrEdit } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MainWorkersTable = () => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const [employees, setEmployees] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getCompanyOfficialEmployee().then((data) => {
      setEmployees(data.data);
    });
  }, []);


  const deleteFunc = (params) => {
    try {
      if (params.id) {
        deleteEmployee(params.id);
      } else {
        console.log("Id doesnt found");
      }
    } catch (err) {
      console.log("Error: ", err);
    }
  };

  const editFunc = (params) => {
    try {
      if (params.id) {
        navigate(`add/${params.id}`);
      } else {
        console.log("id doesnt found");
      }
    } catch (err) {
      console.log("Error: ", err);
    }
  };

  const imageAndNameCellRenderer = (params) => {
    const employee = params.data;
    if (employee) {
      return (
        <div className="flex items-center" onClick={() => editFunc(employee)}>
          {/* <Link
            className="font-bold flex items-center"
            to={`/company/employee/employee_info/${employee.id} `}
          > */}
          <div className="w-[40px] h-[40px]">
            <img
              src={employee.profileUrl}
              alt="user image"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <span className="ml-2">{` ${employee.firstName}  ${employee.lastName} `}</span>
          {/* </Link> */}
        </div>
      );
    } else {
      return "";
    }
  };
  const filteredEmployees = employees.filter((employee) => {
    const fullName = `${employee.firstName} ${employee.lastName}`;
    return fullName.toLowerCase().includes(searchTerm.toLowerCase());
  });

  return (
    <>
      {employees ? (
        <div className="mb-10">
          <div className="relative">
            <label className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-300">
              <BiSearchAlt />
            </label>
            <input
              type="text"
              placeholder={t("EmployeeTable.searchInput")}
              className="pl-8 border border-gray-300 text-gray-900 font-bold rounded-lg py-2 pr-4"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
            />
          </div>

          <div className="relative border-t mt-10 overflow-x-auto shadow-md ">
            <MainTable
              columnDefs={[
                {
                  headerName: t("EmployeeTable.tableSurname"),
                  field: `imageAndNameCellRenderer`,
                  sortable: true,
                  flex: 2,
                  cellRenderer: imageAndNameCellRenderer,
                },
                {
                  headerName: t("EmployeeTable.tableDepartment"),
                  field: "department",
                  sortable: true,
                  flex: 2,
                },
                {
                  headerName: "Баг",
                  field: "team",
                  sortable: true,
                  flex: 2,
                },
                {
                  headerName: t("EmployeeTable.tablePosition"),
                  field: "position",
                  sortable: true,
                  flex: 2,
                },
                {
                  headerName: "",
                  field: "action",
                  sortable: false,
                  cellRenderer: AgCellMenu,
                  flex: 1,
                  cellRendererParams: () => {
                    return {
                      menuItems: [
                        {
                          label: (
                            <div className="flex justify-center items-center">
                              <GrEdit className="me-1" />
                              {t("EmployeeTable.tableEditButton")}
                            </div>
                          ),
                          action: "edit",
                        },
                        {
                          label: (
                            <div className="flex justify-center items-center">
                              <BsFillTrashFill className="me-1" />
                              {t("EmployeeTable.tableDeleteButton")}
                            </div>
                          ),
                          action: "delete",
                        },
                      ],
                      menuActions: {
                        delete: deleteFunc,
                        edit: editFunc,
                      },
                    };
                  },
                },
              ]}
              rowData={filteredEmployees}
            />
          </div>
        </div>
      ) : (
        <div>
          <p className="font-bold text-xl">{t("EmployeeTable.noEmployee")}</p>
          <p>{t("EmployeeTable.noEmployeeAdd")}</p>
        </div>
      )}
    </>
  );
};
export default MainWorkersTable;
